import VueRouter from 'vue-router';



import Login from '../views/Login.vue';
import Dashboard from '../views/Dashboard.vue';

import Cadastros from '../views/Cadastros/Home.vue';
// import Cadastros_Atos from '../views/Cadastros/Atos/Home.vue';
import Cadastros_Atos_Add from '../views/Cadastros/Atos/Adicionar.vue';
import Cadastros_Atos_Edit from '../views/Cadastros/Atos/Editar.vue';
import Cadastros_Remissoes from '../views/Cadastros/Remissoes/Home.vue';
import Cadastros_Remissoes_Add from '../views/Cadastros/Remissoes/Adicionar.vue';
import Cadastros_Remissoes_Edit from '../views/Cadastros/Remissoes/Editar.vue';
import Cadastros_TiposAtos from '../views/Cadastros/Tipos/Atos.vue';
import Cadastros_Temas from '../views/Cadastros/Tipos/Temas.vue';
import Cadastros_Iniciativas from '../views/Cadastros/Tipos/Iniciativas.vue';
import Cadastros_Orgaos from '../views/Cadastros/Tipos/Orgaos.vue';
import Cadastros_Novidades from '../views/Cadastros/Novidades/Novidades.vue';
import Cadastros_Erros from '../views/Cadastros/Erros/ReportErros.vue';
import Cadastros_Sobre from '../views/Cadastros/SaibaMais/Sobre.vue';
import Cadastros_Equipes from '../views/Cadastros/SaibaMais/Equipe.vue';
import Cadastros_LinhaTempo from '../views/Cadastros/SaibaMais/LinhaTempo.vue';

import Historico from '../views/Cadastros/Atos/Historico.vue';

import Cadastros_Constituicao_Add from '../views/Cadastros/Constituicao/Adicionar.vue';
import Cadastros_Constituicao_Edit from '../views/Cadastros/Constituicao/Editar.vue';

import Consultas from '../views/Consultas/Home.vue';

import Configuracoes from '../views/Configuracoes/Home.vue';
import Configuracoes_Usuarios from '../views/Configuracoes/Seguranca/Usuarios.vue';
import Configuracoes_Grupos from '../views/Configuracoes/Seguranca/Grupos.vue';
import Configuracoes_Acessos from '../views/Configuracoes/Seguranca/Acessos.vue';

import Estatisticas from '../views/Estatisticas/Home.vue';

import AtualizacaoAto from "../views/AtualizacoesAtos/Home.vue";

import SemPermissao from '../views/SemPermissao.vue';
import NotFound from '../views/NotFound.vue';



/*enable-eslint*/
const beforeEnter = async (to, from, next) => {
  var isFirstAcess = localStorage.getItem("FirstAcess");
  // eslint-disable-next-line
  if (to.meta.requiresAuth) {
    // eslint-disable-next-line
    // var token = localStorage.getItem("Token")
    // // eslint-disable-next-line
    // await api.post("Usuario/IsFirstAcess?hash=" + token)
    //   .then(response => {
        
    //     isFirstAcess = response.data
    //     

    //   })
    //   .catch(error => {
    //     return error
    //   })
    // eslint-disable-next-line


    if (isFirstAcess == "true") {
      next();
    } else {
       next("/Dashboard");
    }
  } else {
    next("/Dashboard");
  }
};



const routes = [

  {
    path: "*",
    redirect: "/",
  },
  { path: '/Login', name: 'App', component: Login, },
  { path: '/', name: 'Root', component: Dashboard,  },
  { path: '/Dashboard', name: 'Dashboard', component: Dashboard, },

  { path: '/Cadastros', name: 'Cadastros', component: Cadastros, beforeEnter, meta: { requiresAuth: true }, },
  // { path: '/Cadastros/Atos', name: 'Gestão de Leis', component: Cadastros_Atos },
  { path: '/Cadastros/Atos/Adicionar', name: 'Adicionando ato', component: Cadastros_Atos_Add, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Atos/Editar', name: 'Editando ato', component: Cadastros_Atos_Edit, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Remissoes', name: 'Gestão de Remissoes', component: Cadastros_Remissoes, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Remissoes/Adicionar', name: 'Adicionando Remissao', component: Cadastros_Remissoes_Add, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Remissoes/Editar', name: 'Editando Remissao', component: Cadastros_Remissoes_Edit, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Tipos/Atos', name: 'Gestão de Tipos de Atos', component: Cadastros_TiposAtos, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Tipos/Temas', name: 'Gestão de Temas', component: Cadastros_Temas, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Tipos/Iniciativas', name: 'Gestão de Iniciativas', component: Cadastros_Iniciativas, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Tipos/Orgaos', name: 'Gestão de Órgãos', component: Cadastros_Orgaos, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Atos/Historico', name: 'Historico', component: Historico, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Novidades/', name: 'Novidades', component: Cadastros_Novidades, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Erros/', name: 'ReportErros', component: Cadastros_Erros, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Sobre', name: 'Sobre', component: Cadastros_Sobre, beforeEnter, meta: {requiresAuth: true}, },
  { path: '/Cadastros/Equipes', name: 'Equipes', component: Cadastros_Equipes, beforeEnter, meta: {requiresAuth: true}, },
  { path: '/Cadastros/LinhaTempo', name: 'LinhaTempo', component: Cadastros_LinhaTempo, beforeEnter, meta: {requiresAuth: true}, },
  

  { path: '/Cadastros/Constituicao/Adicionar', name: 'Adicionar', component: Cadastros_Constituicao_Add, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Cadastros/Constituicao/Editar', name: 'Editar', component: Cadastros_Constituicao_Edit, beforeEnter, meta: { requiresAuth: true }, },

  { path: '/Consultas', name: 'Consultas', component: Consultas, beforeEnter, meta: { requiresAuth: true }, },

  { path: '/Configuracoes', name: 'Configurações', component: Configuracoes, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Configuracoes/Usuarios', name: 'Gestão de Usuários', component: Configuracoes_Usuarios, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Configuracoes/Grupos', name: 'Gestão de Grupos', component: Configuracoes_Grupos, beforeEnter, meta: { requiresAuth: true }, },
  { path: '/Configuracoes/Acessos', name: 'Gestão de Acessos', component: Configuracoes_Acessos, beforeEnter, meta: { requiresAuth: true }, },

  { path: '/Estatisticas', name: 'Estatísticas', component: Estatisticas, beforeEnter, meta: { requiresAuth: true }, },
  
  { path: '/AtualizacoesAtos', name: 'Atualização de Atos', component: AtualizacaoAto, beforeEnter, meta: { requiresAuth: true }, },

  { path: '/SemPermissao', name: 'Sem Permissão', component: SemPermissao },
  { path: '*', name: 'NotFound', component: NotFound }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});


export default router;