<template>
    <div class="input-component">
      <div class="d-flex justify-content-between align-items-baseline">
        <label class="global-label" v-text="label"></label>
      </div>
      <v-text-field
        class="input"
        :placeholder="placeholder"
        v-model="inputValue"
        validate-on-blur
        flat
        :disabled="disabled ? disabled : false"
        dense
        outlined
        v-mask="mask"
        :hide-details="hideDetails"
        :required="required"
        :color="color ? color : 'primary'"
        :rules="rules"
        :type="checkType"
        :error="error"
        :append-icon="sensive ? (hidden ? 'mdi-eye-off' : 'mdi-eye') : ''"
        @click:append="hidden = !hidden"
      ></v-text-field>
    </div>
  </template>
  
  <script>
  export default {
    name: "AppInput",
  
    data() {
      return {
        hidden: this.sensive,
        defaultValue: this.value,
      };
    },
  
    props: {
      disabled: Boolean,
      label: String,
      placeholder: String,
      sensive: Boolean,
      mask: {
        type: String,
        default: "",
      },
      rules: Array,
      required: {
        type: Boolean,
        default: false,
      },
      color: {
        type: String,
        default: "primary",
      },
      value: String,
      error: {
        type: Boolean,
        default: false,
      },
      hideDetails: {
        type: Boolean,
        default: false,
      },
      type: {
        type: String,
        default: "text",
      },
    },
  
    computed: {
      checkType: function () {
        return this.sensive && this.hidden ? "password" : this.type;
      },
  
      inputValue: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit("input", value);
        },
      },
    },
  };
  </script>
  
  <style>
  .forgot-password-label {
    font-size: 1rem;
    font-weight: 500;
    text-decoration: underline;
  }
  </style>
  