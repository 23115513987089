<template>
    <div class="AddUsuario">
        <modal name="AddUsuario" height="auto">
            <br />
            <div class="container">
                <div class="text-left">
                    <h4>Adicionando usuário</h4>
                    <hr />
                    <form @keyup.enter="salvarUsuario()">
                        <div class="row">
                            <div class="col-md-8">
                                <label>Nome completo<span class="text-danger">*</span></label>
                                <input type="text" v-model="nome" class="form-control rule" />
                            </div>

                            <div class="col-md-4">
                                <label>CPF<span class="text-danger">*</span></label>
                                <input type="text" v-mask="'###.###.###-##'" v-model="cpf" class="form-control rule"/>
                                <span class="text-danger ruleAviso" v-if="cpfAviso">CPF inválido!</span>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-6">
                                <label>E-mail<span class="text-danger">*</span></label>
                                <input type="email" v-model="email" class="form-control"/>
                                <span class="text-danger ruleAviso" v-if="emailAviso">Email inválido!</span>
                            </div>

                            <div class="col-md-6">
                                <label>Senha<span class="text-danger">*</span></label>
                                <input type="password" v-model="senha" class="form-control" />
                            </div>
                        </div>

                        <br />

                        <!-- eslint-disable -->
                        <div class="row">
                            <div class="col-md-12">
                                <label>Órgão<span class="text-danger">*</span></label>
                                <select class="form-control" v-model="orgaoId">
                                    <option v-for="orgao in listOrgaos" :value="orgao.id">{{ orgao.nome }}</option>
                                </select>
                            </div>
                        </div>

                        <br />

                        <div class="row">
                            <div class="col-md-12">
                                <label>Grupo<span class="text-danger">*</span></label>
                                <select class="form-control" v-model="grupoId">
                                    <option v-for="grupo in listGrupos" :value="grupo.id">{{ grupo.nome }}</option>
                                </select>
                            </div>
                        </div>

                        <br />

                        <!-- eslint-enable -->
                        
                        <a @click="salvarUsuario()" href="#" :class="classesButtons" >Salvar</a>
                    </form>
                    <br />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    /* eslint-disable */
    import {validate} from "gerador-validador-cpf";
    export default {
        name: 'AddUsuario',
        props: ["parent"],
        data: () => {
            return ({
                listGrupos: "",
                listOrgaos: "",
                nome: "",
                cpf: "",
                cpfAviso: false,
                email: "",
                emailAviso: false,
                senha: "",
                orgaoId: 1,
                grupoId: 1
            })
        },
        methods: {
            emailAviso: (v) =>
                /^(([^<>()[]\.,;:\s@']+(.[^<>()\[]\.,;:\s@']+)*)|('.+'))@(([[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}])|(([a-zA-Z-0-9]+.)+[a-zA-Z]{2,}))$/
                .test(v) || "O e-mail precisa ser válido."  ,
            fillSelects: function () {
                api.get("/Grupo/Get")
                .then(result => {
                    this.listGrupos = result.data;
                });

                api.get("/Orgao/Get")
                .then(result => {
                    this.listOrgaos = result.data;
                });
            },
            salvarUsuario: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.post("/Usuario/Add?hash=" + cookie, {
                    nome: this.nome,
                    email: this.email,
                    cpf: this.cpf,
                    senha: this.senha,
                    orgaoId: this.orgaoId,
                    grupoId: this.grupoId
                })
                .then(result => {
                    this.$swal("Sucesso", result.data, "success")
                    .then(response => {
                        this.clearFields();
                        this.$props.parent.resetload();
                        this.$modal.hide('AddUsuario');
                    });
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                    }
                    else {
                        this.$swal("Erro", error.response.data, "error");
                    }
                });
            },
            clearFields: function () {
                this.nome = "";
                this.email = "";
                this.cpf = "";
                this.senha = "";
                this.orgaoId = 1;
                this.grupoId = 1;
            }
        },
        mounted() {
            this.fillSelects();
        },
        updated() {
        },
        watch: {
            cpf(cpf) {
                if(validate(cpf)) {
                    this.cpfAviso = false;
                }else this.cpfAviso = true;
            },
            email(email) {
                if(email.match( /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                    this.emailAviso = false;
                }else this.emailAviso = true;
            }
        },
        computed: {
            classesButtons() {
                if(!this.cpfAviso && !this.emailAviso && this.senha && this.nome) {
                    return ['btn', 'btn-info', 'btn-block']
                }else return ['btn', 'btn-info', 'btn-block', 'disabled']
            }
        }
    };
    /* eslint-enable */
</script>

<style scoped>
    .ruleAviso {
        color: red;
        font-size: .7rem;
    }
</style>