<template>
    <div class="Leis">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-file-signature"></i> Histórico {{ d }} {{ ato.tipo }} nº {{ ato.numero }} </h3>
                            <br />
                            <div class="row" id="unseen2">
                                <h3><label>Remissões Ativas</label></h3>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="col-2">Espécie Normativa</th>
                                            <th scope="col" class="col-2">Número</th>
                                            <th scope="col" class="col-2">Ação</th>
                                            <th scope="col" class="col-1">Data do Ato</th>
                                            <th scope="col" class="col-1">Publicação</th>
                                            <th scope="col" class="col-4">Ementa</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- eslint-disable -->
                                        <tr v-for="ra in ativas">
                                            <td>{{ ra.tipoa }}</td>
                                            <td>{{ ra.numeroa }}</td>
                                            <td>{{ ra.acao }}</td>
                                            <td>{{ moment(ra.dataAtoa).format('DD/MM/YYYY') }}</td>
                                            <td>{{ moment(ra.dataPuba).format('DD/MM/YYYY') }}</td>
                                            <td>{{ ra.ementaa }}</td>
                                            <!-- eslint-enable -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row" id="unseen">
                                <h3><label>Remissões Passivas</label></h3>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="col-2">Espécie Normativa</th>
                                            <th scope="col" class="col-2">Número</th>
                                            <th scope="col" class="col-2">Ação</th>
                                            <th scope="col" class="col-1">Data do Ato</th>
                                            <th scope="col" class="col-1">Publicação</th>
                                            <th scope="col" class="col-4">Ementa</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- eslint-disable -->
                                        <tr v-for="rp in passivas">
                                            <td>{{ rp.tipop }}</td>
                                            <td>{{ rp.numerop }}</td>
                                            <td>{{ rp.acao }}</td>
                                            <td>{{ moment(rp.dataAtop).format('DD/MM/YYYY') }}</td>
                                            <td>{{ moment(rp.dataPubp).format('DD/MM/YYYY') }}</td>
                                            <td>{{ rp.ementap }}</td>
                                            <!-- eslint-enable -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>
    </div>
</template>

<script>


    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';

    export default {
        /* eslint-disable */
        name: 'Remissões',
        data: () => {
            return ({
                isAddingUsuario: false,
                ativas: "",
                passivas: "",
                ato: "",
                d: "da"
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton
        },
        methods: {
            load: function () {
                var idato = this.$route.query.id;
                api.get("/Remissao/Historico?idato=" + idato)
                .then(result => {
                    this.ativas = result.data.ativa;
                    this.passivas = result.data.passiva;
                    this.ato = result.data.ato;
                    this.d = result.data.d;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
        },
        mounted: function () {
            this.load();
        }
        /* eslint-enable */
    }
</script>