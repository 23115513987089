<template>
    <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
            <v-icon color="#28A745" size="18px" v-bind="attrs" v-on="on" class="fas fa-eye">
            </v-icon>
        </template>"
        <v-card class="p-4">
            <h4 style="text-align: left; ">
                Visualizando Erro {{ erro.id }}
            </h4>
            <hr>
            <v-container>
                <v-row class="text-left" id="">
                    <v-col cols="12">
                        <div class="container-tipo">Email</div>
                        <v-card-text class="container-texto"> {{ erro.email }}</v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <div class="container-tipo">Tipo de Problema</div>
                        <v-card-text class="container-texto">{{ erro.tipo }}</v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <div class="container-tipo">Descrição do Problema</div>
                        <v-card-text class="container-texto">
                            {{ erro.descricao }}
                        </v-card-text>
                    </v-col>
                    <v-col cols="12">
                        <div class="container-tipo">Anexo</div>
                        <v-card-text class="container-texto">
                            <li v-for="(path, index) in erro.paths" :key="path" @click="checkPath(path)" >
                                <a href=""> {{ erro.nomesArquivos[index] }} </a>
                            </li>
                        </v-card-text>
                    </v-col>
                </v-row>
            </v-container>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="red darken-1" text @click="dialog = false">
                    Fechar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mdiAlertOutline } from '@mdi/js';
import { Api } from '../../../../Service/api'
/* eslint-disable */
export default {
    name: 'DetailsErro',
    props: ["erro"],
    data: () => {
        return ({
            mdiAlertOutline,
            dialog: false,
        })
    },
    methods: {
        reportErro: function (idErro) {
            this.erroAtual = idErro;
            this.showReportErro = true;
        },
        checkPath: function (path) {
            window.open(path.replace('wwwroot', Api.defaults.baseURL))
        },
    },
    mounted() {
    },
    updated() {
    },
    computed: {
    }
};

</script>

<style>
.container-tipo {
    margin-bottom: 1.5px;
    font-size: 1.5rem;
    font-weight: bold;
}

.container-texto {
    margin-top: 0.6em;
    font-size: 0.9rem;
}

.container-titulo {
    font-size: 1.5rem !important;
}
</style>