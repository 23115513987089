<template>
    <div class="EditAtos">
        <NavComponent id="NavComponent"></NavComponent>
        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>
        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-file-signature"></i> Editando ato</h3>
                            <hr />
                            <!-- eslint-disable -->
                            <div class="row">
                                <div class="col-md-3" style="border-right: 1px solid rgb(226 226 226)">
                                    <i :class="iconeItem1"></i> <a class="pointer" style="color: black !important;" @click="goTo(0)"> Cadastro de ficha </a> <!--<span v-else> Cadastro de ficha </span> --> <br />
                                    <i :class="iconeItem2"></i> <a class="pointer" style="color: black !important;" @click="goTo(1)"> Envio de conteúdo/arquivos </a> <!--<span v-else>  Envio de conteúdo/arquivos </span> --> <br />
                                    <i :class="iconeItem3"></i> <a class="pointer" style="color: black !important;" @click="goTo(2)"> Envio de conteúdo/arquivos Atualizado </a> <!-- <span v-else> Envio de conteúdo/arquivos Atualizado </span> --> <br />
                                </div>
                                <div class="col-md-9">
                                    <form>
                                        <div class="row" v-if="idOpcaoAtual == 0">
                                            <div class="col-md-6">
                                                <label>Tipo <span class="text-danger">*</span></label>
                                                <select class="form-control" v-model="ficha.idTipo">
                                                    <option v-for="tipo in tipos" :value="tipo.id">{{ tipo.descricao }}</option>
                                                </select>
                                            </div>

                                            <div class="col-md-6">
                                                <label>Iniciativa <span class="text-danger">*</span></label>
                                                <select class="form-control" v-model="ficha.idIniciativa">
                                                    <option v-for="iniciativa in iniciativas" :value="iniciativa.id">{{ iniciativa.descricao }}</option>
                                                </select>
                                            </div>

                                            <div class="col-md-8">
                                                <label>Número <span class="text-danger">*</span></label>
                                                <input type="text" v-model="ficha.numero" class="form-control" />
                                            </div>

                                            <div class="col-md-4">
                                                <label>Situacao <span class="text-info">(opcional)</span></label>
                                                <select class="form-control" v-model="ficha.idSituacao">
                                                    <option value="0">Selecione</option>
                                                    <option v-for="situacao in situacoes" :value="situacao.id">{{situacao.descricao}}</option>
                                                </select>
                                            </div>

                                            <div class="col-md-4">
                                                <label>Data do Ato <span class="text-danger">*</span></label>
                                                <input type="date" v-model="ficha.dataAto" class="form-control" />
                                            </div>

                                            <div class="col-md-4" v-if="!checkTipo(ficha.idTipo).startsWith('parecer')">
                                                <label>Data de Publicação <span class="text-danger">*</span></label>
                                                <input type="date" v-model="ficha.dataPub" class="form-control" />
                                            </div>

                                            <div class="col-md-4" v-if="!checkTipo(ficha.idTipo).startsWith('parecer')">
                                                <label>Data de Republicação <span class="text-info">(opcional)</span></label>
                                                <input type="date" v-model="ficha.dataRpub" class="form-control" />
                                            </div>

                                            <div class="col-md-12 pb-0">
                                                <label>Ementa <span class="text-danger">*</span></label>
                                                <v-textarea auto-grow v-model="ficha.ementa" outlined class="w-100" />
                                            </div>

                                            <div class="col-md-12 pt-0">
                                                <label>Palavras-chave <span class="text-info">(opcional)</span></label>
                                                <input type="text" v-model="ficha.palavrasChave" class="form-control" placeholder="Separe por ponto e vírgula (;)" />
                                            </div>

                                            <div class="col-md-12">
                                                <label>Órgão relacionado <span class="text-info">(opcional)</span></label>
                                                <div class="row">
                                                    <div class="col-11">
                                                        <select class="form-control" v-model="orgaoSelecionado">
                                                            <option value="null">Selecione</option>
                                                            <option v-for="orgao in orgaos" :value="orgao.id">{{ orgao.nome }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-1">
                                                        <a class="text-info btnAdd-Select" @click="selecionarOrgao()"><i class="fas fa-plus"></i></a>
                                                    </div>
                                                </div>

                                                <div class="row" v-for="orgao in orgaosSelecionados">
                                                    <div class="col-md-12">
                                                        <div class="card">
                                                            <div class="row" style="padding:5px">
                                                                <div class="col-11">
                                                                    {{ orgao.nome }}
                                                                </div>

                                                                <div class="col-1">
                                                                    <a class="text-danger" style="margin-left:7px" v-on:click="removerOrgao(orgao.id)"><i class="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <label>Tema(s) relacionado(s) <span class="text-info">(opcional)</span></label>
                                                <div class="row">
                                                    <div class="col-11">
                                                        <select class="form-control" v-model="temaSelecionado">
                                                            <option value="null">Selecione</option>
                                                            <option v-for="tema in temas" :value="tema.id">{{ tema.descricao }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-1">
                                                        <a class="text-info btnAdd-Select" @click="selecionarTema()"><i class="fas fa-plus"></i></a>
                                                    </div>
                                                </div>

                                                <div class="row" v-for="tema in temasSelecionados">
                                                    <div class="col-md-12">
                                                        <div class="card">
                                                            <div class="row" style="padding:5px">
                                                                <div class="col-11">
                                                                    {{ tema.descricao }}
                                                                </div>
                                                                <div class="col-1">
                                                                    <a class="text-danger" style="margin-left:7px" v-on:click="removerTema(tema.id)"><i class="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row d-flex">
                                                    <div class="col-md-5">
                                                        <label>Origem <span class="text-info">(opcional)</span></label>
                                                        <select class="form-control" v-model="ficha.torigem">
                                                            <option v-for="tipo in tiposorigem" :value="tipo.id">{{ tipo.descricao }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-md-5" v-if="checkTipoOrigem(ficha.torigem) !== 'não se aplica'">
                                                        <label>Número {{ tipoOrigem(ficha.torigem) }}</label>
                                                        <input type="text" v-model="ficha.norigem" class="form-control" />
                                                    </div>
                                                </div>

                                                <div v-if="false" class="row d-flex">
                                                    <div class="col-md-5">
                                                        <label>Parecer <span class="text-info">(opcional)</span></label>
                                                        <div class="d-flex">
                                                            <input type="text" placeholder="Pesquisar pelo numero" v-model="searchParecer" class="form-control" />
                                                            <div>
                                                                <v-btn elevation="2" dark color="#c9c9c9" @click="loadPareceres()">
                                                                    <v-icon>fas fa-search</v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="col-md-7" v-if="pareceres.length !== 0">
                                                        <label>⠀</label>
                                                        <select class="form-control" v-model="ficha.idParecer">
                                                            <option value="0">Selecione o Parecer</option>
                                                            <option v-for="p in pareceres" :value="p.id">{{ p.numero }}{{ moment(p.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? " - " + moment(p.dataAto).format('DD/MM/YYYY') : "" }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="idOpcaoAtual == 1">
                                            <div class="container-file-input">
                                                <form>
                                                    <label>Editor de texto (HTML) - <b>Ato Original</b></label>
                                                    <wysiwyg v-model="textoOrig.textoFormatado" placeholder="Insira aqui o texto" />
                                                    <br />
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <label> Documento .Doc/Docx </label>
                                                            <v-file-input
                                                                label="Selecione o arquivo"
                                                                v-model="arquivoDocx"
                                                                dense
                                                                outlined
                                                                show-size
                                                                accept="application/msword" />
                                                            <span v-if="docxAtual">
                                                                <b>Arquivo atual:</b> <a :target="'_blank'" :href="caminhoBase + docxAtual">{{ docxAtual }}</a>
                                                            </span>
                                                        </div>
                                                        <div class="col-6">
                                                            <label> Documento em .PDF </label>
                                                            <v-file-input
                                                                label="Selecione o arquivo"
                                                                v-model="arquivoPDF"
                                                                dense
                                                                outlined
                                                                show-size
                                                                accept="application/pdf" />
                                                            <span v-if="pdfAtual"> 
                                                                <b>Arquivo atual:</b> <a :target="'_blank'" :href="caminhoBase + pdfAtual">{{ pdfAtual }}</a> 
                                                                <a style="color: red; " @click="removeArquivo(consolidado = false)"><i class="fas fa-trash"></i></a>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div v-if="idOpcaoAtual == 2">
                                            <form>
                                                <label>Editor de texto (HTML) - <b>Ato Atualizado</b></label>
                                                <wysiwyg v-model="textoCons.textoFormatado" placeholder="Insira aqui o texto" />
                                                <br />
                                                <div class="row">
                                                    <div class="col-6">
                                                        <label> Documento <b>Atualizado</b> em .Doc/Docx </label>
                                                        <v-file-input
                                                            label="Selecione o arquivo"
                                                            v-model="arquivoDocxcons"
                                                            dense
                                                            outlined
                                                            show-size
                                                            accept="application/msword" />
                                                        <span v-if="docxAtualcons"><b>Arquivo atual:</b> <a :target="'_blank'" :href="caminhoBase + docxAtualcons">{{ docxAtualcons }}</a></span>
                                                    </div>

                                                    <div class="col-6">
                                                        <label> Documento <b>Atualizado</b> em .PDF </label>
                                                        <v-file-input
                                                            label="Selecione o arquivo"
                                                            v-model="arquivoPDFcons"
                                                            dense
                                                            outlined
                                                            show-size
                                                            accept="application/pdf" />
                                                        <span v-if="pdfAtualcons">
                                                            <b>Arquivo atual:</b> <a :target="'_blank'" :href="caminhoBase + pdfAtualcons">{{ pdfAtualcons }}</a><br/>
                                                            <a style="color: red;" @click="removeArquivo(consolidado = true)"><i class="fas fa-trash"></i></a>
                                                        </span>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div v-if="idOpcaoAtual == 3" class="text-center">
                                            <br />
                                            <i class="fas fa-check text-success fa-3x"></i>
                                            <br />
                                            <br />
                                            <h3>Ato editado com sucesso!</h3>
                                        </div>

                                        <br />

                                        <a @click="salvar()" v-if="idOpcaoAtual < 3" href="#" class="btn btn-info btn-block">{{ opcaoAtual[idOpcaoAtual].texto }}</a>

                                        <a class="btn btn-sm btn-default btn-block btn-primary text-white"
                                            @click="idOpcaoAtual--" 
                                            href="#" 
                                            v-if="idOpcaoAtual > 0 && idOpcaoAtual < 3" >
                                            Voltar
                                        </a>
                                    </form>
                                </div>
                            </div>
                            <!-- eslint-enable -->
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>

    </div>
</template>

<script>
    /* eslint-disable */
    import moment from 'moment'
    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'EditAtos',
        data: () => {
            return ({
                checked: false,
                origemcheck: false,
                ficha: {
                    idTipo: 3,
                    idIniciativa: 1,
                    numero: "",
                    dataAto: null,
                    dataPub: null,
                    dataRpub: null,
                    ementa: "",
                    palavrasChave: null,
                    idOrgaos: "",
                    idTemas: "",
                    user: null,
                    idSituacao: 0,
                    norigem: "",
                    torigem: 0,
                    idParecer: 0,

                },
                textoOrig: {
                    idAto: 0,
                    conteudoHtml: "",
                    textoFormatado: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                    consolidado: false,
                    disp: true,
                },
                textoCons: {
                    idAto: 0,
                    conteudoHtml: "",
                    textoFormatado: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                    consolidado: true,
                    disp: false,
                },
                textoInfo: {
                    id: 0,
                    idAto: 0,
                    Ementa: "",
                    PalavrasChave: "",
                    Numero: "",
                    Tipo: ""
                },
                txtcons: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                txtorig: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                pareceres: [],
                searchParecer: "",
                isAddingUsuario: false,
                idOpcaoAtual: 0,
                opcaoAtual: [{ id: 0, texto: "Confirmar alteração de ficha" },
                { id: 1, texto: "Confirmar a atualização do conteúdo" },
                { id: 2, texto: "Confirmar atualização do conteúdo consolidado"}],
                temas: "",
                situacoes: "",
                orgaoSelecionado: null,
                orgaosSelecionados: [],
                temaSelecionado: null,
                temasSelecionados: [],
                iniciativaSelecionada: 1,
                iniciativas: "",
                orgaos: "",
                tipos: "",
                tiposorigem: [],
                pesquisa: "",
                temaEditando: "",
                caminhoBase: "",

                docxAtual: null,
                pdfAtual: null,
                docxAtualcons: null,
                pdfAtualcons: null,
                atoDisponivel: false,
                idLei: 0,

                conteudoHtml: "",
                arquivoDocx: null,
                arquivoPDF: null,
                conteudoHtmlcons: "",
                arquivoDocxcons: null,
                arquivoPDFcons: null,

                iconeItem1: "fas fa-times text-warning",
                iconeItem2: "fas fa-times text-warning",
                iconeItem3: "fas fa-times text-warning",
                iconeItem4: "fas fa-times text-warning",

                clickableItem: [true, true, true, true],
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton
        },
        methods: {
            loadPareceres: function () {
                if (!this.searchParecer) return this.$swal("Erro", "Insira o número do parecer.", "error");
                var tipo = this.tipos.find(x => x.descricao.toLowerCase().startsWith('parecer'));
                var consulta = {
                    idTipo: tipo.id,
                    numero: this.searchParecer,
                    palavrasChave: "",
                    ementa: ""
                }
                api.post("/Ato/Consulta", consulta)
                .then(result => {
                    this.pareceres = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            checkTipo: function (value) {
                if (typeof this.tipos === Array) {
                    var tp = this.tipos.find(x => x.id === value);
                    if (tp) return tp.descricao.toLowerCase();
                    else return "";
                }
                else return "";
            },
            checkTipoOrigem: function (value) {
                var tp = this.tiposorigem.find(x => x.id === value);
                if (tp)
                    return tp.descricao.toLowerCase();
                else return "";
            },
            tipoOrigem: function (torig) {
                if (torig) {
                    var tipo = this.tiposorigem.find(x => x.id === torig).descricao;
                    if (tipo.toLowerCase().startsWith('lei')) tipo = `da ${tipo}`;
                    else tipo = `do ${tipo}`;
                    return tipo;
                } else return "";
            },
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        // this.$swal("Oops!", error.response.data, "error");
                    })
            },
            openFile: function (nomeArquivo) {
                var caminho = this.caminhoBase + nomeArquivo;
                window.open(caminho, "_blank");
            },
            salvar: async function () {
                if (this.checkTipo(this.ficha.idTipo).startsWith('parecer') || this.checkTipo(this.ficha.idTipo).startsWith('emenda')) {
                    this.ficha.dataPub = "01/01/0001";
                    this.ficha.dataRpub = "01/01/0001";
                    this.ficha.assinatura = 0;
                    this.ass = false;
                } 
                else {
                    if (this.ficha.dataPub === '01/01/0001') this.ficha.dataPub = null;
                    if (this.ficha.dataRpub === '01/01/0001') this.ficha.dataRpub = null;
                }
                if (!this.ficha.dataRpub) this.ficha.dataRpub = "01/01/0001";
                if (this.ficha.torigem && !this.ficha.norigem) return this.$swal("Erro", "Insira o número " + this.tipoOrigem(this.ficha.torigem) + ".", "error");
                if (this.idOpcaoAtual == 0) {
                    this.ficha.idTemas = "";
                    this.ficha.idOrgaos = "";

                    for (var i = 0; i < this.temasSelecionados.length; i++) {
                        this.ficha.idTemas += this.temasSelecionados[i].id + ";";
                    }

                    for (var i = 0; i < this.orgaosSelecionados.length; i++) {
                        this.ficha.idOrgaos += this.orgaosSelecionados[i].id + ";";
                    }

                    this.textoInfo.idAto = this.idLei;
                    this.textoInfo.Ementa = this.ficha.ementa;
                    this.textoInfo.PalavrasChave = this.ficha.palavrasChave;
                    this.textoInfo.Numero = this.ficha.numero;
                    this.textoInfo.Tipo = this.tipos.find(x => x.id === this.ficha.idTipo).descricao;
                    console.log(this.idLei);
                    await api.put("/Texto/EditInfo", this.textoInfo)
                    .catch(error => {
                        console.log(error);
                    });

                    api.post("/Ato/UpdateFicha", this.ficha)
                    .then(result => {
                        this.$swal("Sucesso", result.data.mensagem, "success")
                        .then(response => {
                            this.idLei = result.data.id,
                            this.idOpcaoAtual++;
                            this.updateIndicators();
                        });
                    })
                    .catch(error => {
                        if (error.response.status == 400) {
                            this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                        }
                        else this.$swal("Erro", error.response.data, "error"); 
                    });
                }
                else if (this.idOpcaoAtual == 1) {
                    let formData = new FormData();
                    formData.append('idAto', this.idLei);
                    formData.append('arquivoDocx', this.arquivoDocx);
                    formData.append('arquivoPdf', this.arquivoPDF);
                    formData.append('consolidado', false);

                    var msgf = "";
                    if (this.arquivoDocx !== null || this.arquivoPDF !== null) {
                        await api.post("/Ato/UpdateConteudo", formData)
                        .then(result => {
                            msgf = result.data;
                        })
                        .catch(error => {
                            if (error.response.status == 400) {
                                msgf = "Não foi possível processar, verifique os campos.";
                            }
                            else msgf = error.response.data;
                        });
                    }

                    this.textoOrig.idAto = this.idLei;
                    if (this.textoOrig.textoFormatado !== this.txtorig)
                    {
                        api.get("/Texto/GetOne?id=" + this.idLei + "&disp=true&cons=false")
                        .then(res => {
                            var texto = res.data;
                            if (texto) {
                                api.put("/Texto/Edit", this.textoOrig)
                                .then(result2 => {
                                    this.$swal("Success", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: ${result2.data}`, "success").then(response => {
                                        this.idOpcaoAtual++;
                                        this.updateIndicators();
                                    });
                                })
                                .catch(error1 => {
                                    let errormsg = error1.response.data;
                                    if (errormsg.includes("Illegal unquoted character ((CTRL-CHAR, code 10))")) errormsg = "Erro ao editar o texto. O texto possui algum caractere ilegal.<br>Tente remover quebras de linha ao final do texto.";
                                    this.$swal("Erro", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: ${errormsg}`, "error").then(response => {
                                        if (msgf) {
                                            this.idOpcaoAtual++;
                                            this.updateIndicators();
                                        }
                                    });
                                })
                            }
                            else {
                                api.post("/Texto/Add", this.textoOrig)
                                .then(result3 => {
                                    this.$swal("Success", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: ${result3.data}`, "success").then(response => {
                                        this.idOpcaoAtual++;
                                        this.updateIndicators();
                                    });
                                })
                                .catch(error2 => {
                                    this.$swal("Erro", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: ${error2.response.data}`, "error").then(response => {
                                        if (msgf) {
                                            this.idOpcaoAtual++;
                                            this.updateIndicators();
                                        }
                                    });
                                })
                            }
                        })
                        .catch(err => console.error);
                    }else {
                        if (msgf) {
                            this.$swal("Success", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}`, "success").then(response => {
                                this.idOpcaoAtual++;
                                this.updateIndicators();
                            });
                        }else {
                            this.$swal("Atenção", `Nenhuma alteração foi feita.`, "warning");
                        }
                    }
                }
                else if (this.idOpcaoAtual == 2) {
                    let formData = new FormData();
                    formData.append('idAto', this.idLei);
                    formData.append('arquivoDocx', this.arquivoDocxcons);
                    formData.append('arquivoPdf', this.arquivoPDFcons);
                    formData.append('consolidado', true);

                    var msgf = "";
                    if (this.arquivoDocxcons !== null || this.arquivoPDFcons !== null) {
                        await api.post("/Ato/UpdateConteudo", formData)
                        .then(result => {
                            msgf = result.data;
                        })
                        .catch(error => {
                            if (error.response.status == 400) {
                                msgf = "Não foi possível processar, verifique os campos.";
                            }
                            else msgf = error.response.data;
                        });
                    }

                    this.textoCons.idAto = this.idLei;
                    if (this.textoCons.textoFormatado !== this.txtcons)
                    {
                        api.post("/Texto/Add", this.textoCons)
                        .then(result3 => {
                            this.$swal("Success", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: Texto enviado para consolidação.`, "success").then(response => {
                                this.idOpcaoAtual++;
                                this.updateIndicators();
                            });
                        })
                        .catch(error2 => {
                            this.$swal("Erro", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: ${error2.response.data}`, "error").then(response => {
                                if (msgf) {
                                    this.idOpcaoAtual++;
                                    this.updateIndicators();
                                }
                            });
                        })
                    }else {
                        if (msgf) {
                            this.$swal("Success", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}`, "success").then(response => {
                                this.idOpcaoAtual++;
                                this.updateIndicators();
                            });
                        }else {
                            this.$swal("Atenção", `Nenhuma alteração foi feita.`, "warning");
                        }
                    }
                }
                else if (false) {
                    var destiny = "";
                    if (this.atoDisponivel) destiny = "/Ato/Publish?id=";
                    else destiny = "/Ato/Unpublish?id=";
                        
                    api.post(destiny + this.idLei)
                    .then(result => {
                        this.$swal("Sucesso", result.data, "success")
                        .then(response => {
                            this.idOpcaoAtual++;
                            this.updateIndicators();
                        });
                    })
                    .catch(error => {
                        if (error.response.status == 400) {
                            this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                        }
                        else this.$swal("Erro", error.response.data, "error");
                    });
                }
            },
            selecionarTema: function () {
                for (var i = 0; i < this.temas.length; i++) {
                    if (this.temaSelecionado == this.temas[i].id) {
                        var isNovo = true;
                        for (var x = 0; x < this.temasSelecionados.length; x++) {
                            if (this.temas[i].id == this.temasSelecionados[x].id) {
                                isNovo = false;
                                return;
                            }
                        }
                        if (isNovo = true) this.temasSelecionados.push(this.temas[i]);
                    }
                }
            },
            removerTema: function (id) {
                this.temasSelecionados = this.temasSelecionados.filter(function (value, index, arr) {
                    return value.id != id;
                });
            },
            removerOrgao: function (id) {
                this.orgaosSelecionados = this.orgaosSelecionados.filter(function (value, index, arr) {
                    return value.id != id;
                });
            },
            selecionarOrgao: function () {
                for (var i = 0; i < this.orgaos.length; i++) {
                    if (this.orgaoSelecionado == this.orgaos[i].id) {
                        var isNovo = true;
                        for (var x = 0; x < this.orgaosSelecionados.length; x++) {
                            if (this.orgaos[i].id == this.orgaosSelecionados[x].id) {
                                isNovo = false;
                                return;
                            }
                        }
                        if (isNovo = true) this.orgaosSelecionados.push(this.orgaos[i]);
                    }
                }
            },
            resetData: function () {
                if (this.ficha.dataAto === '01/01/0001') this.ficha.dataAto = null;
                if (this.ficha.dataPub === '01/01/0001') this.ficha.dataPub = null;
                if (this.ficha.dataRpub === '01/01/0001') this.ficha.dataRpub = null;
            },
            fillSelects: function () {
                api.get("/Iniciativa/Get")
                .then(result => {
                    this.iniciativas = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Situacao/Get")
                .then(result => {
                    this.situacoes = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Orgao/Get")
                .then(result => {
                    this.orgaos = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Tema/Get")
                .then(result => {
                    this.temas = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/TipoAto/Get")
                .then(result => {
                    this.tipos = result.data.filter(x => x.permiteCadastro);
                    this.tiposorigem.push({ id: 0, descricao: "Não se aplica", permiteCadastro: false });
                    var tpo = result.data.filter(x => !x.permiteCadastro);
                    this.tiposorigem = this.tiposorigem.concat(tpo);
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            updateIndicators: function () {
                if (this.idOpcaoAtual == 0) {
                    this.iconeItem1 = "fas fa-times text-warning";
                    this.iconeItem2 = "fas fa-times text-warning";
                    this.iconeItem3 = "fas fa-times text-warning";
                    this.iconeItem4 = "fas fa-times text-warning";
                }
                else if (this.idOpcaoAtual == 1) {
                    this.clickableItem[0] = false;
                    this.iconeItem1 = "fas fa-check text-success";
                }
                else if (this.idOpcaoAtual == 2) {
                    this.clickableItem[1] = false;
                    this.iconeItem2 = "fas fa-check text-success";
                }
                else if (this.idOpcaoAtual == 3) {
                    this.clickableItem[2] = false;
                    this.iconeItem3 = "fas fa-check text-success";
                }
                else {
                    this.clickableItem[3] = false;
                    this.iconeItem4 = "fas fa-check text-success";
                }
            },
            getCurrentAto: function () {
                let self = this;
                var id = this.$route.query.id;
                if (id != null || id != undefined || id != 0) {
                    api.get("/Ato/GetOne?id=" + id).then(result => {
                        var ficha = result.data.ficha;
                        var conteudo = result.data.conteudo;
                        var consolidado = result.data.consolidado;
                        var atoDisponivel = result.data.isDisponivel;

                        var idTemas = ficha.idTemas.split(';');
                        var idOrgaos = ficha.idOrgaos.split(';');


                        for (var i = 0; i < idTemas.length; i++) {
                            this.temaSelecionado = idTemas[i];
                            this.selecionarTema();
                        }

                        for (var i = 0; i < idOrgaos.length; i++) {
                            this.orgaoSelecionado = idOrgaos[i];
                            this.selecionarOrgao();
                        }

                        this.orgaoSelecionado = null;
                        this.temaSelecionado = null;

                        this.idLei = ficha.id;
                        
                        this.ficha = ficha;
                        this.ficha.dataAto = moment(ficha.dataAto).format('YYYY-MM-DD');
                        this.ficha.dataPub = moment(ficha.dataPub).format('YYYY-MM-DD');
                        this.ficha.dataRpub = moment(ficha.dataRpub).format('YYYY-MM-DD');
                        if (this.ficha.dataAto === "0001-01-01") this.ficha.dataAto = null;
                        if (this.ficha.dataPub === "0001-01-01") this.ficha.dataPub = null;
                        if (this.ficha.dataRpub === "0001-01-01") this.ficha.dataRpub = null;
                        if (ficha.idSituacao) this.situacao = ficha.idSituacao;
                        if (ficha.idTipoOrigem) this.ficha.torigem = this.ficha.idTipoOrigem;
                        if (ficha.numeroOrigem) this.ficha.norigem = ficha.numeroOrigem;

                        this.conteudoHtml = conteudo.conteudoHtml;
                        this.docxAtual = conteudo.caminhoDocx;
                        this.pdfAtual = conteudo.caminhoPDF;

                        this.conteudoHtmlcons = consolidado.conteudoHtml;
                        this.docxAtualcons = consolidado.caminhoDocx;
                        this.pdfAtualcons = consolidado.caminhoPDF;

                        this.caminhoBase = LEGISON_APIURL + "/uploads/atos/" + ficha.id + "/";

                        this.atoDisponivel = atoDisponivel;
                        this.getConteudo(id);

                    })
                    .catch(error => {
                        this.$swal("Erro", error.response.data, "error");
                    });
                }
                else {
                    $router.push('/NotFound');
                }
            },
            goTo: function (x) {
               this.idOpcaoAtual = x;
            },
            getConteudo: function (id) {
                api.get('/Public/GetConteudoAto?atosids=' + id).then(res => {
                    let content = res.data.content;
                    if (content?.length) {
                        let cons = content.find(x => x.consolidado && x.disp);
                        let orig = content.find(x => !x.consolidado && x.disp);
                        if (cons) {
                            this.textoCons.textoFormatado = cons.textoFormatado ? cons.textoFormatado : "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">";
                            this.txtcons = cons.textoFormatado ? cons.textoFormatado : "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">";
                        }
                        if (orig) {
                            this.textoOrig.textoFormatado = orig.textoFormatado ? orig.textoFormatado : "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">";
                            this.txtorig = orig.textoFormatado ? orig.textoFormatado : "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">";
                        }
                    }
                }).catch(err => {console.log(err)});
            },
            removeArquivo: function(consolidado) {
                var idAto = this.$route.query.id;
                
                this.$swal({
                    title: "Confirme",
                    text: "Você tem certeza que deseja excluir o arquivo "+ this.pdfAtual + " do ato?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Remover Arquivo",
                    cancelButtonText: "Cancelar" 
                }).then( res => {
                    if(res.isConfirmed) {
                        api.delete(`/Ato/RemoveConteudo?IdAto=${idAto}&consolidado=${consolidado}`).then(res => {
                            if(res.status === 204) {
                                this.$swal("Aviso", "Ato excluído, mas não foi encontrado no elastic para exclusão", "warning");
                            }
                            else if (res.status === 200) {
                                this.$swal("Sucesso", res.data, "success");
                            }
                            else {
                                this.$swal("Erro", res.response.data, "error");
                            }
                            if(consolidado) this.pdfAtualcons = false;
                            else this.pdfAtual = false;
                        })
                    }
                }).catch(err=> {
                    this.$swal("Erro", err, "error");
                })
            }
        },
        computed: {
            renderHtml() {
                return this.conteudoHtml;
            },
            renderHtmlcons() {
                return this.conteudoHtmlcons;
            }
        },
        beforeMount: function () {
            this.resetData();
        },
        mounted: function () {
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
            this.fillSelects();
            this.getCurrentAto();
        },
        updated: function () {
            // this.updateIndicators();
        }
    }
    /* eslint-enable */
</script>

<style>
    .container-input-file {
        clear: both;
    }
    .container-input-file input {
        clear: both;
    }
    .v-text-field--outlined.v-input--dense .v-label {
        top: 2px !important;
    }
</style>


