import Vue from 'vue';
import vuetify from './plugins/vuetify' // path to vuetify export

import VueRouter from 'vue-router';
import VModal from 'vue-js-modal'
import VueMask from 'v-mask'
import VueSweetalert2 from 'vue-sweetalert2';
import wysiwyg from "vue-wysiwyg";
import moment from 'moment';
import VueToggles from 'vue-toggles';
import vueTopprogress from 'vue-top-progress'
import VueQuillEditor from 'vue-quill-editor'

import 'sweetalert2/dist/sweetalert2.min.css';
import 'quill/dist/quill.snow.css';
import "vue-wysiwyg/dist/vueWysiwyg.css";

Vue.use(vueTopprogress)
Vue.component('VueToggles', VueToggles);
Vue.prototype.moment = moment
Vue.use(wysiwyg, {});
Vue.use(VueSweetalert2);
Vue.use(VueRouter);
Vue.use(VueQuillEditor)
Vue.use(require('vue-cookies'))
Vue.use(VModal)
Vue.use(VueMask);

import router from './router/Routes';
import App from './App.vue';


import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';
import "./assets/styles/variables.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css';
Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);


import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
library.add(faUserSecret);
Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')

Vue.$cookies.config('1d')

