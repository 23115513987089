<template>
    <div class="cardatos">
        <v-hover v-slot="{ hover }">
            <div class="cardatos mt-5">
                <v-lazy v-model="isActive"
                    :options="{ threshold: .5 }"
                    transition="fade-transition"
                    class="cardatos">

                    <v-card :elevation="hover ? 4 : 2"
                            :class="{ 'on-hover': hover }"
                            color="#DFDFDF"
                            v-on:click="toggleShows()"
                            rounded="lg">

                        <div class="card-results d-flex flex-no-wrap align-items-center flex-column flex-sm-row w-100">
                            <div class="d-flex flex-no-wrap flex-column flex-sm-row align-items-center px-4 py-4 w-100 pr-0">
                                <v-img :src="require(`@/assets/imgs/logodecreto.png`)"
                                       alt="Logo do Governo do Estado"
                                       class="mb-3 mb-sm-0"
                                       contain
                                       max-height="46"
                                       max-width="37" />

                                <div class="d-flex flex-column text-left ml-sm-4 w-100">
                                    <div class="d-flex">
                                        <div class="my-auto col-md-4 justify-content-start">
                                            <v-card-title v-text="prepareTitle(ficha)" class="text-sm-left text-center" />
                                            <v-card-subtitle v-text="prepareDate(ficha)" class="text-sm-left text-center" />
                                        </div>

                                        <div class="my-auto col-md-7">
                                            <span class="ementaDesc text-sm-right text-center">{{ prepareEmenta(ficha) }}</span>
                                        </div>

                                        <div class="my-auto col-md-1">
                                            <div class="d-flex">
                                                <!-- <a :id="'hist' + ato.id" class="text-secondary" href="#" v-on:click="$router.push('/Cadastros/Atos/Historico?id=' + ato.id)"><i class="fas fa-archive"></i></a>
                                                <b-tooltip :target="'hist' + ato.id" triggers="hover">
                                                    Histórico do Ato.
                                                </b-tooltip> -->

                                                <a :id="'edit' + ficha.id" class="text-primary" href="#" v-on:click="$router.push('/Cadastros/Constituicao/Editar?id=' + ficha.id)"><center><i class="fas fa-edit"></i></center></a>
                                                <b-tooltip :target="'edit' + ficha.id" triggers="hover">
                                                    Editar Constituição.
                                                </b-tooltip>

                                                <a :id="'del' + ficha.id" class="text-danger" href="#" v-on:click="remove(ficha)"><i class="fas fa-trash"></i></a>
                                                <b-tooltip :target="'del' + ficha.id" triggers="hover">
                                                    Excluir Constituição.
                                                </b-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <v-spacer />

                            <div class="d-flex px-4">
                                <v-expand-transition>
                                    <i class="fas fa-angle-down"></i>
                                </v-expand-transition>
                            </div>

                        </div>

                    </v-card>
                </v-lazy>
            </div>
        </v-hover>
        <div class="cardatos" v-if="show">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="col-3">Data da Constituição</th>
                        <th scope="col" class="col-3">Data de Publicação</th>
                        <th scope="col" class="col-2">Situação</th>
                        <th scope="col" class="col-2">Iniciativa</th>
                        <th scope="col" class="col-2">Conteúdo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ moment(ficha.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? moment(ficha.dataAto).format('DD/MM/YYYY') : "Não Informado" }}</td>
                        <td>{{ moment(ficha.dataPublicacao).format('DD/MM/YYYY') !== "01/01/0001" ? moment(ficha.dataPublicacao).format('DD/MM/YYYY') : "Não Informado" }}</td>
                        <td>{{ loadSituacao(ficha) }}</td>
                        <td>{{ loadIniciativa(ficha) }}</td>
                        <td>
                            <div class="d-flex justify-content-center justify-itens-center">
                                <div v-if="ficha.texto">
                                    <v-dialog v-model="dialogHTML"
                                              fullscreen>

                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'conteudo' + ficha.id" class="text-primary" href="#" v-on="on"><i class="fas fa-file-code"></i></a>
                                            <b-tooltip :target="'conteudo' + ficha.id" triggers="hover">
                                                Texto Original.
                                            </b-tooltip>
                                        </template>

                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ficha.descricao }} {{ moment(ficha.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ficha.dataAto).format('DD/MM/YYYY') + ")" : "" }} - Original</span>
                                                <v-spacer></v-spacer>

                                                <v-btn icon
                                                       dark
                                                       @click="dialogHTML = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>

                                            <v-card-text class="w-100">
                                                <br />
                                                <iframe class="w-100"  style="height: 85vh !important;"
                                                    :srcdoc="ficha.texto"
                                                    v-html="ficha.texto"
                                                    frameborder="0"
                                                    scrolling="yes"
                                                    marginheight="0"
                                                    marginwidth="0" />
                                            </v-card-text>

                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="fas fa-file-code"></i></a>
                                </div>
                                <div v-if="ficha.textoAtualizado">
                                    <v-dialog v-model="dialogHTMLcons"
                                              fullscreen>
                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'conteudoc' + ficha.id" class="text-primary" href="#" v-on="on"><i class="far fa-file-code"></i></a>
                                            <b-tooltip placement="bottom" :target="'conteudoc' + ficha.id" triggers="hover">
                                                Texto Atualizado.
                                            </b-tooltip>
                                        </template>
                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ficha.descricao }} {{ moment(ficha.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ficha.dataAto).format('DD/MM/YYYY') + ")" : "" }} - Atualizada</span>
                                                <v-spacer></v-spacer>
                                                <v-btn icon
                                                       dark
                                                       @click="dialogHTMLcons = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <v-card-text class="w-100" style="height: 85vh !important;">
                                                <br />
                                                <iframe class="w-100 h-100"
                                                    :srcdoc="ficha.textoAtualizado"
                                                    v-html="ficha.textoAtualizado"
                                                    frameborder="0"
                                                    scrolling="yes"
                                                    marginheight="0"
                                                    marginwidth="0" />
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="far fa-file-code"></i></a>
                                </div>
                                <div v-if="ficha.arquivo">
                                    <v-dialog v-model="dialogPDF"
                                              fullscreen>
                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'pdf' + ficha.id" class="text-primary" href="#" v-on="on"><i class="fas fa-file-pdf"></i></a>
                                            <b-tooltip :target="'pdf' + ficha.id" triggers="hover">
                                                Arquivo Original.
                                            </b-tooltip>
                                        </template>
                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ficha.descricao }} {{moment(ficha.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ficha.dataAto).format('DD/MM/YYYY') + ")" : ""}} - Original</span>
                                                <v-spacer></v-spacer>
                                                <v-btn icon
                                                       dark
                                                       @click="dialogPDF = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <div id="ItemDisplay" class="h-100 d-flex justify-content-center flex-column" v-if="getSourcePdf()">
                                                <iframe :src="pdfsrc"
                                                        frameborder="0"
                                                        scrolling="yes"
                                                        marginheight="0"
                                                        marginwidth="0"
                                                        class="pdf" />
                                            </div>
                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="fas fa-file-pdf"></i></a>
                                </div>
                                <div v-if="ficha.arquivoAtualizado">
                                    <v-dialog v-model="dialogPDFcons"
                                              fullscreen>
                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'pdfc' + ficha.id" class="text-primary" href="#" v-on="on"><i class="far fa-file-pdf"></i></a>
                                            <b-tooltip placement="bottom" :target="'pdfc' + ficha.id" triggers="hover">
                                                Arquivo Atualizado.
                                            </b-tooltip>
                                        </template>
                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ficha.descricao }} {{ moment(ficha.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ficha.dataAto).format('DD/MM/YYYY') + ")" : "" }} - Atualizada</span>
                                                <v-spacer></v-spacer>
                                                <v-btn icon
                                                       dark
                                                       @click="dialogPDFcons = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <div id="ItemDisplay" class="h-100 d-flex justify-content-center flex-column" v-if="getPdfConsolidado()">
                                                <iframe :src="pdfcons"
                                                        frameborder="0"
                                                        scrolling="yes"
                                                        marginheight="0"
                                                        marginwidth="0"
                                                        class="pdf" />
                                            </div>
                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="far fa-file-pdf"></i></a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="col-6"><center>Ementa</center></th>
                        <th scope="col" class="col-6"><center>Descrição</center></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ ficha.ementa }}</td>
                        <td>{{ ficha.descricao }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {
        prepareDate as utilPrepareDate,
    } from '@/utils/prepareTextAto.js';

    import { mdiClose, mdiFileCheck } from '@mdi/js';
/* eslint-disable */
    const moment = require('moment');
    
    export default {
        name: 'CardConst',
        data: () => {
            return ({
                icons: {
                    mdiClose,
                    mdiFileCheck
                },
                pdfsrc: null,
                docsrc: null,
                doccons: null,
                pdfcons: null,
                dialogDOCcons: false,
                dialogHTMLcons: false,
                dialogPDFcons: false,
                dialogDOC: false,
                dialogHTML: false,
                dialogPDF: false,
                isActive: false,
                pathObj: null,
                showing: false,
                ficha: {
                    id: 0,
                    idIniciativa: 1,
                    dataAto: null,
                    dataPublicacao: null,
                    ementa: "",
                    descricao: "",
                    idSituacao: 0,
                    texto: "",
                    textoAtualizado: "",
                    arquivo: null,
                    arquivoAtualizado: null,
                    iniciativa: "",
                    situacao: ""
                }
            })
        },
        methods: {
            getPdfConsolidado: function () {
                if (this.ficha.arquivoAtualizado) {
                    if (this.pdfcons) return true;
                    const id = this.ficha.id;
                    this.pdfcons = `${LEGISON_APIURL}/uploads/constituicao/${id}/atualizado/${this.ficha.arquivoAtualizado}`;
                    if (!this.pdfcons.endsWith('.pdf')) this.pdfcons += ".pdf";
                    return true;
                }
                return false;
            },
            getSourcePdf: function () {
                if (this.ficha.arquivo) {
                    if (this.pdfsrc) return true;
                    const id = this.ficha.id;
                    this.pdfsrc = `${LEGISON_APIURL}/uploads/constituicao/${id}/${this.ficha.arquivo}`;
                    if (!this.pdfsrc.endsWith('.pdf')) this.pdfsrc += ".pdf";
                    return true;
                }
                return false;
            },
            getInfo: async function () {
                api.get("/Constituicao/Get")
                .then(response => {
                    var ato = response.data;
                    this.ficha.id = ato.id;
                    this.ficha.idIniciativa = ato.idIniciativa;
                    this.ficha.idSituacao = ato.idSituacao;
                    this.ficha.dataAto = ato.dataAto;
                    this.ficha.dataPublicacao = ato.dataPublicacao;
                    this.ficha.ementa = ato.ementa;
                    this.ficha.descricao = ato.descricao;
                    this.ficha.texto = ato.texto;
                    this.ficha.textoAtualizado = ato.textoAtualizado;
                    this.ficha.arquivo = ato.caminho;
                    this.ficha.arquivoAtualizado = ato.caminhoAtualizado;
                }).catch(err => {
                    this.$swal("Erro", err.response.data, "error");
                });
            },
            loadIniciativa: function (ato) {
                if (ato.idIniciativa) {
                    api.get("/Iniciativa/GetOne?id=" + ato.idIniciativa)
                    .then(response => {
                        var iniciativa = response.data;
                        this.ficha.iniciativa = iniciativa.descricao;
                    })
                    .catch(err => {
                        this.ficha.iniciativa = "Não Informado";
                    });
                } else this.ficha.iniciativa = "Não Informado";
                return this.ficha.iniciativa;
            },
            toggleShows: function () {
                if (this.showing) this.showing = false;
                else {
                    this.showing = true;
                }
            },
            prepareTitle: function () {
                return this.ficha.descricao.toUpperCase();
            },
            prepareDate: function () {
                var data = this.ficha.dataAto;
                if (!data) return "";
                if (data?.toString().startsWith("0001-01-01")) data = this.ficha.dataPublicacao;
                if (data?.toString().startsWith("0001-01-01")) data = "";
                if (data === "") return "";
                else return `de ${utilPrepareDate(data)}`.toUpperCase();
            },
            prepareEmenta: function (ato) {
                var ementa = ato.ementa;
                return ementa;
            },
            loadSituacao: function (ato) {
                if (ato.idSituacao) {
                    api.get("/Situacao/GetOne?id=" + ato.idSituacao)
                    .then(response => {
                        var situacao = response.data;
                        this.ficha.situacao = situacao.descricao;
                    })
                    .catch(err => {
                        this.ficha.situacao = "Não Informado";
                    });
                } else this.ficha.situacao = "Não Informado";
                return this.ficha.situacao;
            },
            remove: function (obj) {
                this.$swal({
                    title: "Confirme",
                    text: "Você tem certeza que deseja excluir a Constituição?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: "Remover",
                    cancelButtonText: "Cancelar"
                })
                .then(response => {
                    if (response.isConfirmed) {
                        api.delete("/Constituicao/Remove?id=" + obj.id)
                        .then(result => {
                            this.$swal("Sucesso", result.data, "success");
                            this.$parent.consti = false;
                        })
                        .catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            },
        },
        beforeMount: function () {
            this.getInfo();
        },
        computed: {
            show: function () {
                return this.showing;
            }
        }
    }
    /* eslint-enable */
</script>

<style>
    @import './CardAto.css';
</style>
