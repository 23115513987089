const rules = {
    requiredRule: (value) => !!value || "Campo obrigatório",
  
    nameRule: (value) => {
      if (/[0-9]/.test(value)) return "Nome inválido";
      else if (!value.trim().includes(" ")) return "Insira o nome completo";
      else return true;
    },
  
    userSGPRule: () => {
      return true;
    },
  
    passwordRule: (value) => {
      return value.length >= 8 || "A senha deve possuir pelo menos 8 caracteres";
    },
    tituloRule: (value) => {
      /*eslint-disable*/
      if (!/^([\w\s.\u00E0-\u00FC]{3,100})$/i.test(value)) return "Titulo Invalido";
    },
    descricaoRules: (value) => {
      /*eslint-disable*/
      // if (/[`~!@#$%^&*()_|+\w\s\-=?;:'",.<>\{\}\[\]\\\/]/i.test(value)) return "Descriçao inválida";
      if (!/^([\w\s.\u00E0-\u00FC]{3,500})$/i.test(value)) return "Descrição inválida";
    },
    titleRules: (value) => {
      return value.length <= 100 || "Máx de 100 caracteres";
    },
  
    descricaoRule: (value) => {
      return value.length <= 500 || "Máx de 500 caracteres";
    },
    processosRule: (value) => {
  
      return value[value.length - 1].length >= 12 && value[value.length - 1].length <= 13 || "Numero do Processo Inválido, Insira 12 caracteres.";
  
  
  
    },
   
  };
  
  export default rules;
  