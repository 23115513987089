<template>
    <div class="Orgaos">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-list-ol"></i> Órgãos</h3>
                            <br />
                            <div class="row d-flex">
                                <input type="text" @keyup="searchTimeout()" v-model="pesquisa" class="form-control" placeholder="Pesquise pelo nome do órgão" />
                            </div>
                            <br />
                            <div class="row" id="unseen">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <!-- eslint-disable -->
                                    <tbody>
                                        <tr v-for="orgao in orgaoArr">
                                            <td>{{ orgao.id }}</td>
                                            <td>{{ orgao.nome }}</td>
                                            <td>
                                                <a class="text-primary" href="#" @click="editOrgao(orgao)"><i class="fas fa-edit"></i></a>
                                                <a class="text-danger" href="#" @click="removeOrgao(orgao)"><i class="fas fa-trash"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <!-- eslint-enable -->
                                </table>
                            </div>
                            <center>
                                <v-btn v-if="displaySeeMore"
                                       plain
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                                <!--
                                <v-btn v-if="displaySeeMore"
                                       plain
                                       :loading="fetching"
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                                -->
                            </center>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>

        <AddOrgao :parent="this" />

        <EditOrgao :orgao="orgaoEditando" />

        <a @click="addOrgao()"  class="btnFloat">
            <FloatButton id="FloatButton" icon="fas fa-plus"></FloatButton>
        </a>
      
    </div>
</template>

<script>
    /* eslint-disable */
    import AddOrgao from './components/Orgaos/AddOrgao.vue';
    import EditOrgao from './components/Orgaos/EditOrgao.vue';

    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'Orgaos',
        data: () => {
            return ({
                isAddingUsuario: false,
                orgaos: "",
                pesquisa: "",
                orgaoEditando: "",
                user: null,
                count: 0,
                results: 15,
                request: 0,
                listener: 1,
                orgaoArr: [],
                timeout: null,
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton,
            AddOrgao,
            EditOrgao
        },
        methods: {
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    })
            },
            resetload: function () {
                this.count = 0;
                this.orgaoArr = [];
                this.handleLoadMore();
            },
            searchTimeout: function () {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null
                }
                this.timeout = setTimeout(() => {
                    this.pesquisar();
                }, 800);
            },
            pesquisar: function () {
                this.orgaoArr = [];
                this.count = 0;
                this.orgaos = "";
                this.loadOrgaos();
            },
            loadOrgaos: function () {
                api.get("/Orgao/GetX?pesquisa=" + this.pesquisa + "&count=" + this.count + "&results=" + this.results)
                .then(result => {
                    this.orgaos = result.data;
                    if (this.listener == 0 && this.orgaos.length >= this.results) this.listener = 1;
                    if (this.orgaos.length < this.results) this.listener = 0;         
                    this.orgaoArr = this.orgaoArr.concat(this.orgaos);
                    this.count += this.results;
                    this.request = 1;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            addOrgao: function () {
                this.$modal.show('AddOrgao');
            },
            editOrgao: function (orgao) {
                this.orgaoEditando = orgao;
                this.$modal.show('EditOrgao');
            },
            removeOrgao: function (orgao) {
                this.$swal({
                    title: "Confirme",
                    text: "Você tem certeza que deseja excluir o órgão " + orgao.nome + "?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Remover',
                    cancelButtonText: 'Cancelar'
                })
                .then(response => {
                    if (response.isConfirmed) {
                        api.delete("/Orgao/Remove?id=" + orgao.id + "&uid=" + this.user.id)
                        .then(result => {
                            this.$swal("Sucesso", result.data, "success");
                            this.loadOrgaos();
                        })
                        .catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            },
            handleLoadMore: function () {
                this.listener = 1;
                this.request = 0;
                this.loadOrgaos();
            },
            getResults: function () {
                this.listener = 1;
                this.loadOrgaos();
            }
        },
        mounted: function () {
            this.getResults();
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
        },
        computed: {
            displaySeeMore: function () {
                return this.listener;
            },
        },
    }
    /* eslint-enable */
</script>

