<template>
    <div class="AddAtos">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <!-- eslint-disable -->
                        <div class="container text-left">

                            <br />

                            <h3 class="bold-title"><i class="fas fa-file-signature"></i> Adicionando ato</h3>
                            
                            <hr />
                            
                            <div class="row">
                                <div class="col-md-3" style="border-right: 1px solid rgb(226 226 226)">
                                    <i :class="iconeItem1"></i> Cadastro de ficha  <br />
                                    <i :class="iconeItem2"></i> Envio de conteúdo/arquivos  <br />
                                    <i :class="iconeItem3"></i> Envio de conteúdo/arquivos Atualizado  <br />
                                </div>
                                <div class="col-md-9">

                                    <form>
                                        <div class="row" v-if="idOpcaoAtual == 0">
                                            <div class="col-md-6">
                                                <label>Tipo <span class="text-danger">*</span></label>
                                                <select class="form-control" v-model="ficha.idTipo">
                                                    <option v-for="tipo in tipos" :value="tipo.id">{{ tipo.descricao }}</option>
                                                </select>
                                            </div>

                                            <div class="col-md-6">
                                                <label>Iniciativa <span class="text-danger">*</span></label>
                                                <select class="form-control" v-model="ficha.idIniciativa">
                                                    <option v-for="iniciativa in iniciativas" :value="iniciativa.id">{{ iniciativa.descricao }}</option>
                                                </select>
                                            </div>

                                            <div class="col-md-8">
                                                <label>Número <span class="text-danger">*</span></label>
                                                <input type="text" v-model="ficha.numero" class="form-control" />
                                            </div>

                                            <div class="col-md-4">
                                                <label>Situação <span class="text-info">(opcional)</span></label>
                                                <select class="form-control" v-model="ficha.idSituacao">
                                                    <option value="0">Selecione</option>
                                                    <option v-for="situacao in situacoes" :value="situacao.id">{{ situacao.descricao }}</option>
                                                </select>
                                            </div>

                                            <div class="col-md-4">
                                                <label>Data do Ato <span class="text-danger">*</span></label>
                                                <input type="date" v-model="ficha.dataAto" class="form-control" />
                                            </div>

                                            <div class="col-md-4" v-if="!checkTipo(ficha.idTipo).startsWith('parecer')">
                                                <label>Data de Publicação
                                                     <span class="text-info" v-if="checkTipo(ficha.idTipo).startsWith('emenda')">(opcional)</span>
                                                     <span v-else class="text-danger">*</span>
                                                </label>
                                                <input type="date" v-model="ficha.dataPub" class="form-control" />
                                            </div>

                                            <div class="col-md-4" v-if="!checkTipo(ficha.idTipo).startsWith('parecer')">
                                                <label>Data de Republicação <span class="text-info">(opcional)</span></label>
                                                <input type="date" v-model="ficha.dataRpub" class="form-control" />
                                            </div>

                                            <div class="col-md-12 pb-0">
                                                <label>Ementa <span class="text-danger">*</span></label>
                                                <v-textarea auto-grow v-model="ficha.ementa" outlined class="w-100" />
                                            </div>

                                            <div class="col-md-12 pt-0">
                                                <label>Palavras-chave <span class="text-info">(opcional)</span></label>
                                                <input type="text" v-model="ficha.palavrasChave" class="form-control" placeholder="Separe por ponto e vírgula (;)" />
                                            </div>

                                            <div class="col-md-12">
                                                <label>Órgão relacionado <span class="text-info">(opcional)</span></label>

                                                <div class="row">
                                                    <div class="col-11">
                                                        <select class="form-control" v-model="orgaoSelecionado">
                                                            <option value="null">Selecione</option>
                                                            <option v-for="orgao in orgaos" :value="orgao.id">{{ orgao.nome }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-1">
                                                        <a class="text-info btnAdd-Select" v-on:click="selecionarOrgao()"><i class="fas fa-plus"></i></a>
                                                    </div>
                                                </div>

                                                <div class="row" v-for="orgao in orgaosSelecionados">
                                                    <div class="col-md-12">
                                                        <div class="card">
                                                            <div class="row" style="padding:5px">
                                                                <div class="col-11">
                                                                    {{ orgao.nome }}
                                                                </div>

                                                                <div class="col-1">
                                                                    <a class="text-danger" style="margin-left:7px" v-on:click="removerOrgao(orgao.id)">
                                                                        <i class="fas fa-times"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12">
                                                <label>Tema(s) relacionado(s) <span class="text-info">(opcional)</span></label>
                                                <div class="row">
                                                    <div class="col-11">
                                                        <select class="form-control" v-model="temaSelecionado">
                                                            <option value="null">Selecione</option>
                                                            <option v-for="tema in temas" :value="tema.id">{{ tema.descricao }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-1">
                                                        <a class="text-info btnAdd-Select" v-on:click="selecionarTema()"><i class="fas fa-plus"></i></a>
                                                    </div>
                                                </div>

                                                <div class="row" v-for="tema in temasSelecionados">
                                                    <div class="col-md-12">
                                                        <div class="card">
                                                            <div class="row" style="padding:5px">
                                                                <div class="col-11">
                                                                    {{ tema.descricao }}
                                                                </div>

                                                                <div class="col-1">
                                                                    <a class="text-danger" style="margin-left:7px" v-on:click="removerTema(tema.id)"><i class="fas fa-times"></i></a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div class="row d-flex">
                                                    <div class="col-md-5">
                                                        <label>Origem <span class="text-info">(opcional)</span></label>
                                                        <select class="form-control" v-model="ficha.torigem">
                                                            <option v-for="tipo in tiposorigem" :value="tipo.id">{{ tipo.descricao }}</option>
                                                        </select>
                                                    </div>

                                                    <div class="col-md-5" v-if="checkTipoOrigem(ficha.torigem) !== 'não se aplica'">
                                                        <label>Número {{ tipoOrigem(ficha.torigem) }}</label>
                                                        <input type="text" v-model="ficha.norigem" class="form-control" />
                                                    </div>
                                                </div>

                                                <div v-if="false" class="row d-flex">
                                                    <div class="col-md-5">
                                                        <label>Parecer <span class="text-info">(opcional)</span></label>
                                                        <v-text-field 
                                                            solo
                                                            v-model="searchParecer"
                                                            clearable
                                                            placeholder="Pesquise aqui">

                                                            <template slot="append">
                                                                <v-icon v-on:click="loadPareceres()">fas fa-search</v-icon>
                                                            </template>
                                                            
                                                        </v-text-field>
                                                    </div>

                                                    <div class="col-md-7" v-if="pareceres.length !== 0">
                                                        <label>⠀</label>
                                                        <select class="form-control" v-model="ficha.idParecer">
                                                            <option value="0">Selecione o Parecer</option>
                                                            <option v-for="p in pareceres" :value="p.id">{{ p.numero }}{{ moment(p.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? " - " + moment(p.dataAto).format('DD/MM/YYYY') : "" }}</option>
                                                        </select>
                                                    </div>
                                                </div>

                                                <div class="row d-flex mt-2" v-if="grupo.includes(1) && ficha.idTipo !== 5 && ficha.idTipo !== 6">
                                                    <div class="col-md-12">
                                                        <div class="row" style="padding:5px">
                                                            <div class="col-4 d-flex align-items-center">
                                                                <input type="checkbox" id="chass" style="height:30px; width: auto" v-model="ass" />
                                                                <label class="form-check-label ml-2 mt-1" for="chass">Assinatura Completa</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-md-12 mt-2">
                                                <div class="row">
                                                    <div class="col-md-6 d-flex align-items-center">
                                                        <input type="checkbox" id="chremi" class="" style="height:30px; width: auto" v-model="checked">
                                                        <label class="form-check-label ml-2" style="margin-top:0; width:100%" for="chremi">Cadastrar Remissão</label>
                                                    </div>
                                                </div>

                                                <div v-if="checked">
                                                    <label>Esta Remissão é:</label>
                                                    <div class="row">
                                                        <div class="col-11 d-flex">
                                                            <section class="d-flex flex-column">
                                                                <div class="d-flex align-items-center">
                                                                    <input type="radio" id="atv" v-model="rTipo" class="mx-2" value="ativa" style="height:30px; width: auto" />
                                                                    <label for="atv" class="mt-2">Ativa</label>
                                                                </div>

                                                                <div class="d-flex align-items-center">
                                                                    <input type="radio" id="passv" v-model="rTipo" value="passiva" class="mx-2" style="height: 30px; width: auto" />
                                                                    <label for="passv" class="mt-2">Passiva</label>
                                                                </div>
                                                            </section>
                                                        </div>
                                                    </div>

                                                    <div class="row">
                                                        <div class="col-6">
                                                            <label>Número do Ato {{ rNum[rTipo.toLowerCase()] }}:</label>

                                                            <v-text-field 
                                                                solo
                                                                v-model="searchRemissao"
                                                                :placeholder="'Pesquise aqui pelo número do Ato ' + (rNum[rTipo.toLowerCase()] ? rNum[rTipo.toLowerCase()] : '')">
                                                                <template slot="append">
                                                                    <v-icon v-on:click="loadRemissoes()">fas fa-search</v-icon>
                                                                </template>
                                                                
                                                            </v-text-field>
                                                        </div>

                                                         <div class="col-6" v-if="remissoes.length !== 0">
                                                            <label>Selecione o Ato {{ rNum[rTipo.toLowerCase()] }}:</label>
                                                            <v-autocomplete
                                                                solo
                                                                clearable
                                                                v-model="remissao.numAtiva"
                                                                no-data-text="Não foi encontrado um ato com o número pesquisado."
                                                                :menu-props="{ transition: 'scroll-y-transition' }"
                                                                :items="remissoes"
                                                                :item-text="item => item.tipoAto.descricao + ' Nº '  + item.numero + (moment(item.dataAto).format('DD/MM/YYYY') !== '01/01/0001' ? ' - ' + moment(item.dataAto).format('DD/MM/YYYY') : '')" 
                                                                :item-value="'id'">
                                                                <template v-slot:append>
                                                                    <v-icon style="cursor: pointer">{{ iconDropdown }}</v-icon>
                                                                </template>
                                                            </v-autocomplete>
                                                        </div>
                                                    </div>
                                                    <div class="col-12">
                                                        <label>Ação:</label>
                                                        <select class="form-control" v-model="remissao.acao">
                                                            <option value="0" selected>Selecione</option>
                                                            <option v-for="acao in acoes" :value="acao.id">{{ acao.descricao }}</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div v-if="idOpcaoAtual == 1">
                                            <div class="container-file-input">
                                                <form>
                                                    <label>Editor de texto (HTML) - <b>Ato Original</b></label>
                                                    <wysiwyg v-model="textoOrig.textoFormatado" placeholder="Insira aqui o texto" />
                                                    <br />
                                                    <div class="row">
                                                        <div class="col-6">
                                                            <label> Documento em .Doc/Docx </label>
                                                            <v-file-input
                                                                label="Selecione o arquivo"
                                                                v-model="arquivoDocx"
                                                                dense
                                                                outlined
                                                                show-size
                                                                accept="application/msword" />
                                                        </div>
                                                        <div class="col-6">
                                                            <label> Documento em .PDF 
                                                                <span class="font-weight-bold">(Max: 30MB)</span> 
                                                            </label>
                                                            <v-file-input
                                                                label="Selecione o arquivo"
                                                                v-model="arquivoPDF"
                                                                dense
                                                                outlined
                                                                show-size
                                                                accept="application/pdf" />
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                        <div v-if="idOpcaoAtual == 2">
                                            <form>
                                                <label>Editor de texto (HTML) - <b>Ato Atualizado</b> </label>
                                                <wysiwyg v-model="textoCons.textoFormatado" placeholder="Insira aqui o texto" />
                                                <br />
                                                <div class="row">
                                                    <div class="col-6">
                                                        <label> Documento <b>Atualizado</b> em .Doc/Docx </label>
                                                        <v-file-input
                                                            label="Selecione o arquivo"
                                                            v-model="arquivoDocxcons"
                                                            dense
                                                            outlined
                                                            show-size
                                                            accept="application/msword" />
                                                        <!--
                                                        <span v-if="docxAtualcons"> 
                                                            <b>Arquivo atual:</b> 
                                                            <a :target="'_blank'" :href="caminhoBase + docxAtualcons">{{ docxAtualcons }}</a>
                                                        </span>
                                                        -->
                                                    </div>

                                                    <div class="col-6">
                                                        <label> Documento <b>Atualizado</b> em .PDF <b>(Max: 30MB)</b></label>
                                                        <v-file-input
                                                            label="Selecione o arquivo"
                                                            v-model="arquivoPDFcons"
                                                            dense
                                                            outlined
                                                            show-size
                                                            accept="application/pdf" />
                                                        <!--
                                                        <span v-if="pdfAtualcons">
                                                            <b>Arquivo atual:</b> 
                                                            <a :target="'_blank'" :href="caminhoBase + pdfAtualcons">{{ pdfAtualcons }}</a>
                                                        </span>
                                                        -->
                                                    </div>
                                                </div>
                                            </form>
                                        </div>

                                        <div v-if="idOpcaoAtual == 3">
                                            <h4 class="text-success">Ato cadastrado com sucesso!</h4>
                                            <p>
                                                Aguardando validação e publicação pela equipe responsável.
                                            </p>
                                        </div>

                                        <br />

                                        <a @click="salvar()" style="color:#fff !important" v-if="idOpcaoAtual < 3" href="#" class="btn btn-info btn-block">{{ opcaoAtual[idOpcaoAtual].texto }}</a>
                                    </form>
                                </div>
                            </div>
                            <br />
                        </div>
                        <!-- eslint-enable -->
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>
    </div>
</template>

<script>
    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';
    import { mdiChevronDown } from '@mdi/js';

    export default {
        /* eslint-disable */
        name: 'AddAtos',
        data: () => {
            return ({
                checked: false,
                origemcheck: false,
                ass: false,
                grupo: [],
                rNum: { ativa: 'Passivo', passiva: 'Ativo' },
                ficha: {
                    idTipo: 3,
                    idIniciativa: 1,
                    numero: "",
                    dataAto: null,
                    dataPub: null,
                    dataRpub: null,
                    ementa: "",
                    palavrasChave: null,
                    idOrgaos: "",
                    idTemas: "",
                    assinatura: 0,
                    user: null,
                    idSituacao: 0,
                    norigem: "",
                    torigem: 0,
                    idParecer: 0
                },
                textoOrig: {
                    idAto: 0,
                    conteudoHtml: "",
                    textoFormatado: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                    consolidado: false,
                    disp: true,
                },
                textoCons: {
                    idAto: 0,
                    conteudoHtml: "",
                    textoFormatado: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                    consolidado: true,
                    disp: false,
                },
                txtcons: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                txtorig: "<div title=\"header\" style=\"color: rgb(0, 0, 0); font-family: &quot;Times New Roman&quot;; font-size: medium;\"><p align=\"center\" style=\"text-align: center; margin-bottom: 0cm; direction: ltr; background: transparent;\"><img src=\"https://i.imgur.com/hQorHaT.png\" name=\"LOGO-GOVERNO-SERGIPE.png\" align=\"bottom\" width=\"189\" height=\"227\" border=\"0\"></p><div title=\"header\">",
                remissao: {
                    numAtiva: null,
                    numPassiva: null,
                    acao: 0
                },
                procuraRemissao: {
                    numAtiva: "",
                    numPassiva: ""
                },
                pareceres: [],
                remissoes: [],
                searchParecer: "",
                searchRemissao: "",
                rTipo: "",
                isAddingUsuario: false,
                idOpcaoAtual: 0,
                opcaoAtual: [{ id: 0, texto: "Confirmar cadastro de ficha" },
                { id: 1, texto: "Confirmar cadastro do conteúdo original" },
                { id: 2, texto: "Confirmar cadastro do conteúdo atualizado" }],
                temas: "",
                situacoes: "",
                orgaoSelecionado: null,
                orgaosSelecionados: [],
                temaSelecionado: null,
                temasSelecionados: [],
                iniciativaSelecionada: 1,
                iniciativas: "",
                conteudoHtml: "",
                orgaos: "",
                tipos: [],
                pesquisa: "",
                temaEditando: "",
                acaoSelecionada: null,
                acoesSelecionadas: [],
                idLei: 0,
                acoes: "",
                tiposorigem: [],
                arquivoDocx: null,
                arquivoPDF: null,
                conteudoHtmlcons: "",
                arquivoDocxcons: null,
                arquivoPDFcons: null,

                iconDropdown: mdiChevronDown,
                iconeItem1: "fas fa-times text-warning",
                iconeItem2: "fas fa-times text-warning",
                iconeItem3: "fas fa-times text-warning",
                iconeItem4: "fas fa-times text-warning",
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton
        },
        methods: {
            loadPareceres: function () {
                if (!this.searchParecer) return this.$swal("Erro", "Insira o número do parecer.", "error");
                var tipo = this.tipos.find(x => x.descricao.toLowerCase().startsWith('parecer'));
                var consulta = {
                    idTipo: tipo.id,
                    numero: this.searchParecer,
                    palavrasChave: "",
                    ementa: ""
                }
                api.post("/Ato/Consulta", consulta).then(result => {
                    this.pareceres = result.data;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            loadRemissoes: function () {
                if (!this.searchRemissao) return this.$swal("Erro", "Insira o número do ato.", "error");
                if (this.rTipo == "ativa") {
                    this.procuraRemissao.numPassiva = this.searchRemissao;
                    this.procuraRemissao.numAtiva = "";
                }
                else if (this.rTipo == "passiva") {
                    this.procuraRemissao.numAtiva = this.searchRemissao;
                    this.procuraRemissao.numPassiva = "";
                }
                else return this.$swal("Erro", "Indique o tipo da remissão.", "error");
                api.post("/Remissao/Procurar", this.procuraRemissao)
                .then(result => {
                    if (this.rTipo == "ativa") this.remissoes = result.data.passiva;
                    else this.remissoes = result.data.ativa;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                })
            },
            checkTipo: function (value) {
                var tp = this.tipos.find(x => x.id === value);
                if (tp) return tp.descricao.toLowerCase();
                else return "";
            },
            checkTipoOrigem: function (value) {
                var tp = this.tiposorigem.find(x => x.id === value);
                if (tp) return tp.descricao.toLowerCase();
                else return "";
            },
            tipoOrigem: function (torig) {
                if (torig) {
                    var tipo = this.tiposorigem.find(x => x.id === torig).descricao;
                    if (tipo.toLowerCase().startsWith('lei')) tipo = `da ${tipo}`;
                    else tipo = `do ${tipo}`;
                    return tipo;
                } 
                else return "";
            },
            getGrupo: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        var user = response.data;
                        this.user = user;
                        this.grupo = user.usuario_Grupos.map(e => { return e.idGrupo });
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    });

            },
            handleFileUpload: function (tipo, consolidado) {
                if (tipo == "docx") {
                    if (consolidado) this.arquivoDocxcons = this.$refs.fileDocxcons.files[0];
                    else this.arquivoDocx = this.$refs.fileDocx.files[0];
                }
                else {
                    if (consolidado)  this.arquivoPDFcons = this.$refs.filePdfcons.files[0];
                    else  this.arquivoPDF = this.$refs.filePdf.files[0];
                }
            },
            salvar: async function () {
                if (this.ass) this.ficha.assinatura = 1;
                if (this.checkTipo(this.ficha.idTipo).startsWith('parecer')) {
                    this.ficha.dataPub = "01/01/0001";
                    this.ficha.dataRpub = "01/01/0001";
                    this.ficha.assinatura = 0;
                    this.ass = false;
                } else {
                    if (this.ficha.dataPub === '01/01/0001') this.ficha.dataPub = null;
                    if (this.ficha.dataRpub === '01/01/0001') this.ficha.dataRpub = null;
                }
                if (!this.ficha.dataRpub) this.ficha.dataRpub = "01/01/0001";
                if (!this.ficha.ementa) return this.$swal("Erro", "Insira a ementa do ato.", "error");
                if (!this.ficha.numero) return this.$swal("Erro", "Insira a número do ato.", "error");
                if (this.ficha.torigem && !this.ficha.norigem) return this.$swal("Erro", "Insira o número " + this.tipoOrigem(this.ficha.torigem) + ".", "error");
                if (this.idOpcaoAtual == 0) {
                    var postremi = false;
                    this.ficha.idTemas = "";
                    this.ficha.idOrgaos = "";
                    for (var i = 0; i < this.temasSelecionados.length; i++) {
                        this.ficha.idTemas += this.temasSelecionados[i].id + ";";
                    }
                    for (var i = 0; i < this.orgaosSelecionados.length; i++) {
                        this.ficha.idOrgaos += this.orgaosSelecionados[i].id + ";";
                    }
                    if (this.checked && (!this.remissao.numAtiva || !this.remissao.acao || this.remissao.acao === '0' || this.rTipo === "")) {
                        this.$swal("Erro", "Campo obrigatório não preenchido.", "error");
                    } 
                    else {
                        if(this.checkTipo(this.ficha.idTipo).startsWith('emenda')) {
                            this.ficha.dataPub = '01/01/0001';
                        }
                        api.post("/Ato/AddFicha", this.ficha)
                        .then(result => {
                            if (this.checked) {
                                var idl = result.data.id;
                                if (this.rTipo === "ativa")
                                {
                                    this.remissao.numPassiva = this.remissao.numAtiva;
                                    this.remissao.numAtiva = idl;
                                }
                                else if (this.rTipo === "passiva")
                                {
                                    this.remissao.numPassiva = idl;
                                }
                                api.post("/Remissao/AddRemissao", this.remissao)
                                .then(result => {
                                    this.$swal("Sucesso", result.data.mensagem, "success")
                                    .then(response => {
                                        this.idLei = idl,
                                        this.idOpcaoAtual++;
                                    });
                                })
                                .catch(error => {
                                    if (error.response.status == 400) {
                                        this.$swal("Erro", "Não foi possível processar, verifique os campos. [Remissao]", "error");
                                    }
                                    else this.$swal("Erro", error.response.data, "error");
                                });
                            } 
                            else {
                                this.$swal("Sucesso", result.data.mensagem, "success")
                                .then(response => {
                                    this.idLei = result.data.id,
                                    this.idOpcaoAtual++;
                                });
                            }
                        })
                        .catch(error => {
                            if (error.response.status == 400) {
                                this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                            }
                            else this.$swal("Erro", error.response.data, "error");
                        });
                    }
                }
                else if (this.idOpcaoAtual == 1) {
                    let formData = new FormData();
                    formData.append('idAto', this.idLei);
                    formData.append('arquivoDocx', this.arquivoDocx);
                    formData.append('arquivoPdf', this.arquivoPDF);
                    formData.append('consolidado', false);
                    
                    let msgf = "";
                    if (this.arquivoDocx !== null || this.arquivoPDF !== null) {
                        if (this.arquivoPDF.size > 30000000){
                            this.$swal("Erro", "Não é permitido enviar arquivos maiores ou iguais a 30MB", "error");
                            throw new Exception("Não são permitidos arquivos maiores que 30MB");    
                        }
                        await api.post("/Ato/AddConteudo", formData)
                        .then(result => {
                            msgf = result.data;
                        })
                        .catch(error => {
                            if (error.response.status == 400) {
                                msgf = "Não foi possível processar, verifique os campos.";
                            }
                            if (error.response.status == 413) {
                                msgf = "Não é permitido enviar arquivos maiores que 30MB";
                            }
                            else msgf = error.response.data;
                        });
                    }

                    this.textoOrig.idAto = this.idLei;
                    if (this.textoOrig.textoFormatado !== this.txtorig)
                    {
                        api.post("/Texto/Add", this.textoOrig)
                        .then(result3 => {
                            this.$swal("Success", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: ${result3.data}`, "success").then(response => {
                                this.idOpcaoAtual++;
                                this.updateIndicators();
                            });
                        })
                        .catch(error2 => {
                            this.$swal("Erro", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: ${error2.response ? error2.response.data : error2.message}`, "error").then(response => {
                                if (msgf) {
                                    this.idOpcaoAtual++;
                                    this.updateIndicators();
                                }
                            });
                        })
                    }else {
                        if (msgf) {
                            this.$swal("Success", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}`, "success").then(response => {
                                this.idOpcaoAtual++;
                                this.updateIndicators();
                            });
                        }else {
                            this.$swal("Atenção", `Nenhuma alteração foi feita.<br>a`, "warning").then(response => {
                                this.idOpcaoAtual++;
                            });
                        }
                    }
                }
                else if (this.idOpcaoAtual == 2) {
                    let formData = new FormData();
                    formData.append('idAto', this.idLei);
                    formData.append('arquivoDocx', this.arquivoDocxcons);
                    formData.append('arquivoPdf', this.arquivoPDFcons);
                    formData.append('consolidado', true);

                    let msgf = "";
                    console.log(this.arquivoPDFcons);
                    if (this.arquivoDocxcons !== null || this.arquivoPDFcons !== null) {
                        console.log(this.arquivoPDFcons);
                        console.log(this.arquivoPDFcons.size);
                        if (this.arquivoPDFcons.size > 30000000){
                            this.$swal("Erro", "Não é permitido enviar arquivos maiores ou iguais a 30MB", "error");
                            throw new Exception("Não são permitidos arquivos maiores que 30MB");
                        }
                        await api.post("/Ato/AddConteudo", formData)
                        .then(result => {
                            msgf = result.data;
                        })
                        .catch(error => {
                            if (error.response.status == 400) {
                                msgf = "Não foi possível processar, verifique os campos.";
                            }
                            if (error.response.status == 413) {
                                msgf = "Não é permitido enviar arquivos maiores que 30MB";
                            }
                            else msgf = error.response.data;
                        });
                    }

                    this.textoCons.idAto = this.idLei;
                    if (this.textoCons.textoFormatado !== this.txtcons)
                    {
                        api.post("/Texto/Add", this.textoCons)
                        .then(result3 => {
                            this.$swal("Success", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: Texto enviado para consolidação.`, "success").then(response => {
                                this.idOpcaoAtual++;
                                this.updateIndicators();
                            });
                        })
                        .catch(error2 => {
                            this.$swal("Erro", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}<br>Envio do texto: ${error2.response.data}`, "error").then(response => {
                                if (msgf) {
                                    this.idOpcaoAtual++;
                                    this.updateIndicators();
                                }
                            });
                        })
                    }else {
                        if (msgf) {
                            this.$swal("Success", `Envio do arquivo: ${msgf ? msgf : 'Nenhuma alteração feita.'}`, "success").then(response => {
                                this.idOpcaoAtual++;
                                this.updateIndicators();
                            });
                        }else {
                            this.$swal("Atenção", `Nenhuma alteração foi feita.`, "warning").then(response => {
                                this.idOpcaoAtual++;
                            });
                        }
                    }
                }
                // else if (false) {
                //     api.post("/Ato/Publish?id=" + this.idLei)
                //     .then(result => {
                //         this.$swal("Sucesso", result.data, "success")
                //         .then(response => {
                //             this.idOpcaoAtual++;
                //         });
                //     })
                //     .catch(error => {
                //         if (error.response.status == 400) {
                //             this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                //         }
                //         else this.$swal("Erro", error.response.data, "error");
                //     });
                // }
            },
            selecionarTema: function () {
                for (var i = 0; i < this.temas.length; i++) {
                    if (this.temaSelecionado == this.temas[i].id) {
                        var isNovo = true;
                        for (var x = 0; x < this.temasSelecionados.length; x++) {
                            if (this.temas[i].id == this.temasSelecionados[x].id) {
                                isNovo = false;
                                return;
                            }
                        }
                        if (isNovo = true) this.temasSelecionados.push(this.temas[i]);
                    }
                }
            },
            selecionarAcao: function () {
                for (var i = 0; i < this.acoes.length; i++) {
                    if (this.acaoSelecionada == this.acoes[i].id) {
                        var isNovo = true;
                        for (var x = 0; x < this.acoesSelecionadas.length; x++) {
                            if (this.acoes[i].id == this.acoesSelecionadas[x].id) {
                                isNovo = false;
                                return;
                            }
                        }
                        if (isNovo = true) this.acoesSelecionadas.push(this.acoes[i]); 
                    }
                }
            },
            removerTema: function (id) {
                this.temasSelecionados = this.temasSelecionados.filter(function (value, index, arr) {
                    return value.id != id;
                });
            },
            removerOrgao: function (id) {
                this.orgaosSelecionados = this.orgaosSelecionados.filter(function (value, index, arr) {
                    return value.id != id;
                });
            },
            resetData: function () {
                if (this.ficha.dataPub === '01/01/0001') this.ficha.dataPub = null;
                if (this.ficha.dataRpub === '01/01/0001') this.ficha.dataRpub = null;
            },
            selecionarOrgao: function () {
                for (var i = 0; i < this.orgaos.length; i++) {
                    if (this.orgaoSelecionado == this.orgaos[i].id) {
                        var isNovo = true;
                        for (var x = 0; x < this.orgaosSelecionados.length; x++) {
                            if (this.orgaos[i].id == this.orgaosSelecionados[x].id) {
                                isNovo = false;
                                return;
                            }
                        }
                        if (isNovo = true) this.orgaosSelecionados.push(this.orgaos[i]);
                    }
                }
            },
            fillSelects: function () {
                api.get("/Iniciativa/Get").then(result => {
                    this.iniciativas = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Situacao/Get").then(result => {
                    this.situacoes = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Orgao/Get").then(result => {
                    this.orgaos = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Tema/Get").then(result => {
                    this.temas = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/TipoAto/Get").then(result => {
                    this.tipos = result.data.filter(x => x.permiteCadastro);
                    this.tiposorigem.push({ id: 0, descricao: "Não se aplica", permiteCadastro: false });
                    var tpo = result.data.filter(x => !x.permiteCadastro);
                    this.tiposorigem = this.tiposorigem.concat(tpo);
                    this.ficha.torigem = this.tiposorigem.find(x => x.id === 0).id;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/Acao/Get").then(result => {
                    this.acoes = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            updateIndicators: function () {
                if (this.idOpcaoAtual == 0) {
                    this.iconeItem1 = "fas fa-times text-warning";
                    this.iconeItem2 = "fas fa-times text-warning";
                    this.iconeItem3 = "fas fa-times text-warning";
                    this.iconeItem4 = "fas fa-times text-warning";
                }
                else if (this.idOpcaoAtual == 1) {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-times text-warning";
                    this.iconeItem3 = "fas fa-times text-warning";
                    this.iconeItem4 = "fas fa-times text-warning";
                }
                else if (this.idOpcaoAtual == 2) {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-check text-success";
                    this.iconeItem3 = "fas fa-times text-warning";
                    this.iconeItem4 = "fas fa-times text-warning";
                }
                else if (this.idOpcaoAtual == 3) {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-check text-success";
                    this.iconeItem3 = "fas fa-check text-success";
                    this.iconeItem4 = "fas fa-times text-warning";
                }
                else {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-check text-success";
                    this.iconeItem3 = "fas fa-check text-success";
                    this.iconeItem4 = "fas fa-check text-success";
                }
            },
            goTo: function (x) {
                this.idOpcaoAtual = x;
            }
        },
        beforeMount: function () {
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getGrupo();
        },
        mounted: function () {
            this.fillSelects();
        },
        updated: function () {
            this.updateIndicators();
        }
        /* eslint-enable */
    }
</script>

<style>
    input {
        background: #FFF !important;
    }
    ::-webkit-input-placeholder {
        color: #495057 !important;
    }
    .container-input-file {
        width: 500px;
        clear: both;
    }
    .container-input-file input {
        width: 100%;
        clear: both;
    }
</style>

