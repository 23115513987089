<template>
    <div class="Leis">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-file-export"></i> Remissões </h3>
                            <br />
                            <div class="row">
                                <input type="text" v-on:keyup="searchTimeout" v-model="pesquisa" class="form-control" placeholder="Pesquise pelo número do Ato" />
                            </div>
                            <br />
                            <div class="row" id="unseen">
                                <h3><label>Remissões Ativas</label></h3>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="col-2">Espécie Normativa</th>
                                            <th scope="col" class="col-2">Número</th>
                                            <th scope="col" class="col-4">Ementa</th>
                                            <th scope="col" class="col-2">Ação</th>
                                            <th scope="col" class="col-1">Data do Ato</th>
                                            <th scope="col" class="col-1">Publicação</th>
                                            <th scope="col" class="col-1">Opções</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- eslint-disable -->
                                        <tr v-for="ra in remissoes">
                                            <td>{{ ra.tipoa }}</td>
                                            <td>{{ ra.numeroa }}</td>
                                            <td>{{ ra.ementaa }}</td>
                                            <td>{{ ra.acao }}</td>
                                            <td>{{ moment(ra.dataAtoa).format('DD/MM/YYYY') }}</td>
                                            <td>{{ moment(ra.dataPuba).format('DD/MM/YYYY') }}</td>
                                            <td>
                                                <a class="text-primary" href="#" v-on:click="$router.push('/Cadastros/Remissoes/Editar?id=' + ra.id)"><i class="fas fa-edit"></i></a>
                                                <a class="text-danger" href="#" v-on:click="remove(ra)"><i class="fas fa-trash"></i></a>
                                            </td>
                                            <!-- eslint-enable -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="row" id="unseen">
                                <h3><label>Remissões Passivas</label></h3>
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col" class="col-2">Espécie Normativa</th>
                                            <th scope="col" class="col-2">Número</th>
                                            <th scope="col" class="col-4">Ementa</th>
                                            <th scope="col" class="col-2">Ação</th>
                                            <th scope="col" class="col-1">Data do Ato</th>
                                            <th scope="col" class="col-1">Publicação</th>
                                            <th scope="col" class="col-1">Opções</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- eslint-disable -->
                                        <tr v-for="rp in remissoes">
                                            <td>{{ rp.tipop }}</td>
                                            <td>{{ rp.numerop }}</td>
                                            <td>{{ rp.ementap }}</td>
                                            <td>{{ rp.acao }}</td>
                                            <td>{{ moment(rp.dataAtop).format('DD/MM/YYYY') }}</td>
                                            <td>{{ moment(rp.dataPubp).format('DD/MM/YYYY') }}</td>
                                            <td>
                                                <a class="text-primary" href="#" v-on:click="$router.push('/Cadastros/Remissoes/Editar?id=' + rp.id)"><i class="fas fa-edit"></i></a>
                                                <a class="text-danger" href="#" v-on:click="remove(rp)"><i class="fas fa-trash"></i></a>
                                            </td>
                                            <!-- eslint-enable -->
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <br />
                            <div class="container justify-content-center mb-2">
                                <center>
                                    <svg xmlns="http://www.w3.org/2000/svg" v-on:click="onClickLeft()" width="16" height="16" fill="currentColor" class="mx-2 bi bi-arrow-left-circle-fill btn-outline-new" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.5 7.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H11.5z" />
                                    </svg>
                                    <svg xmlns="http://www.w3.org/2000/svg" v-on:click="onClickRight()" width="16" height="16" fill="currentColor" class="bi bi-arrow-right-circle-fill btn-outline-new" viewBox="0 0 16 16">
                                        <path d="M8 0a8 8 0 1 1 0 16A8 8 0 0 1 8 0zM4.5 7.5a.5.5 0 0 0 0 1h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 1 0-.708.708L10.293 7.5H4.5z" />
                                    </svg>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>

        <a @click="$router.push('/Cadastros/Remissoes/Adicionar')" class="btnFloat">
            <FloatButton id="FloatButton" icon="fas fa-plus"></FloatButton>
        </a>

    </div>
</template>

<script>

    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'Remissões',
        data: () => {
            return ({
                isAddingUsuario: false,
                remissoes: "",
                pesquisa: "",
                count: 0,
                results: 5,
                remArr: [],
                request: 0,
                lr: "",
                lg: 0,
                user: null
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton
        },
        methods: {
            /* eslint-disable */
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    })
            },
            load: function () {
                api.get("/Remissao/GetX?pesquisa=" + this.pesquisa + "&count=" + this.count + "&results=" + this.results)
                .then(result => {
                    this.remissoes = result.data.rem;
                    this.lg = result.data.lg;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            searchTimeout: function (event) {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null
                }
                if (event.key === 'Enter')
                    this.pesquisar();
                else
                    if (!['control', 'alt'].includes(event.key.toLowerCase()))
                        this.timeout = setTimeout(() => {
                            this.pesquisar();
                        }, 800);
            },
            pesquisar: function () {
                this.count = 0;
                this.load();
            },
            add: function () {
                this.$modal.show('Add');
            },
            remove: function (obj) {
                this.$swal({
                    title: "Confirme",
                    text: `Você tem certeza que deseja excluir a remissão: ${obj.tipoa} nº ${obj.numeroa} ${obj.acao} ${obj.tipop} nº ${obj.numerop}?`,
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Remover',
                    cancelButtonText: 'Cancelar'
                })
                .then(response => {
                    if (response.isConfirmed) {
                        api.delete("/Remissao/Remove?id=" + obj.id + "&uid=" + this.user.id)
                        .then(result => {
                            this.$swal("Sucesso", result.data, "success");
                            this.load();
                        })
                        .catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            },
            onClickLeft: function () {
                if (this.count > 0) this.request = 1;
                if (this.request) {
                    this.lr = 'l';
                    this.request = 0;
                    this.count -= this.results;
                    this.load();
                }
            },
            onClickRight: function () {
                if (this.remissoes.length+this.lg >= this.results) this.request = 1;
                if (this.request) {
                    this.lr = 'r';
                    this.request = 0;
                    this.count += this.results;
                    this.load();
                }
            },
            /*getNext10: function () {
                window.addEventListener('scroll', this.onScroll);
                this.onScroll();
            }*/
        },
        /*beforeDestroy() {
            window.removeEventListener('scroll', this.onScroll);
        },*/
        mounted: function () {
            this.load();
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
            //this.getNext10();
        }
    }
</script>


