<template>
    <div class="AddGrupo">
        <modal name="AddGrupo" height="auto">
            <br />
            <div class="container">
                <div class="text-left">
                    <h4>Adicionando grupo</h4>
                    <hr />
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Nome</label>
                                <input @keyup.enter="salvarGrupo()" type="text" v-model="nome" class="form-control" />
                            </div>
                        </div>
                        <br />
                        <a @click="salvarGrupo()" href="#" class="btn btn-info btn-block">Salvar</a>
                    </form>
                    <br />
                </div>
            </div>

        </modal>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: 'AddGrupo',
        props: ["parent"],
        data: () => {
            return ({
                nome: "",
            })
        },
        methods: {         
            salvarGrupo: function () {
                api.post("/Grupo/Add" + "?uid=" + this.$props.parent.user.id, {
                    nome: this.nome,
                })
                .then(result => {
                    this.$swal("Sucesso", result.data, "success")
                    .then(response => {
                        this.clearFields();
                        this.$props.parent.loadGrupos();
                        this.$modal.hide('AddGrupo');
                    });
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                    }
                    else {
                        this.$swal("Erro", error.response.data, "error");
                    }
                });
            },
            clearFields: function () {
                this.nome = "";
            }
        },
        mounted() {
        },
        updated() {
        }
    };
    /* eslint-enable */
</script>