<template>
    <div class="EditAcesso">
        <modal name="EditAcesso" height="auto">
            <br />
            <div v-if="acesso != ''" class="container">
                <div class="text-left">
                    <h4>Editando acesso #{{ acesso.id }}</h4>
                    <hr />
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Caminho</label>
                                <input @keyup.enter="salvarAcesso()" type="text" v-model="acesso.endereco" class="form-control" />
                            </div>                            
                        </div>
                        <br />                    
                        <a @click="salvarAcesso()" href="#" class="btn btn-info btn-block">Salvar</a>
                    </form>
                    <br />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: 'EditAcesso',
        props: ["acesso", "parent"],
        data: () => {
            return ({
                listGrupos: "",
                listOrgaos: "",
                user: null
            })
        },
        methods: {           
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    })
            },
            salvarAcesso: function () {
                api.post("/Acesso/Edit" + "?uid=" + this.$props.parent.user.id, {
                    id: this.$props.acesso.id,
                    endereco: this.$props.acesso.endereco,
                    idGrupo: this.$props.parent.grupoSelecionado
                })
                .then(result => {
                    this.$swal("Sucesso", result.data, "success")
                    .then(response => {
                        this.$modal.hide('EditAcesso');
                    });
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                    }
                    else this.$swal("Erro", error.response.data, "error");
                });
            }
        },
        mounted() {
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
        },
        updated() {
        }
    };
    /* eslint-enable */
</script>
