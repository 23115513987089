<template>
    <div class="EditAtos">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-file-signature"></i> Editando Remissão</h3>
                            <hr />
                            <div class="row">
                                <div class="col-md-3" style="border-right: 1px solid rgb(226 226 226)">
                                    <i :class="iconeItem1"></i> Atualizar Remissão  <br />
                                </div>
                                <div class="col-md-9">
                                    <form>
                                        <div class="row form-group" v-if="idOpcaoAtual < 2">
                                            <div class="col-md-6">
                                                <label>Número do Ato Ativo</label>
                                                <input type="text" v-model="fichaProcura.numAtiva" :placeholder="ficha.numAtiva" class="form-control" />
                                            </div>
                                            <div class="col-md-6">
                                                <label>Número do Ato Passivo</label>
                                                <input type="text" v-model="fichaProcura.numPassiva" :placeholder="ficha.numPassiva" class="form-control" />
                                            </div>
                                            <div v-if="ativas || passivas" class="d-flex col-md-12">
                                                <div class="mt-4 col-6">
                                                    <label v-if="ativas.length > 0">Selectione o Ato Ativo</label>
                                                    <label v-else>Nenhum Ato Encontrado.</label>
                                                    <!-- eslint-disable -->
                                                    <br />
                                                    <label v-for="r in ativas" class="d-flex mx-2">
                                                        <b-button :pressed.sync="checkeda.find(x => x.id === r.id).state" :id="r.id" v-on:click="selectedA(r.id)" class="col-12">
                                                            {{ tipos.find(x => x.id === r.idTipoAto).descricao }} nº {{ r.numero }} - {{ moment(r.dataCriacao).format('DD/MM/YYYY') }}.
                                                        </b-button>
                                                    </label>
                                                    <!-- eslint-enable -->
                                                </div>
                                                <div class="mt-4 ml-2 col-6">
                                                    <label v-if="passivas.length > 0">Selecione o Ato Passivo</label>
                                                    <label v-else>Nenhum Ato Encontrado.</label>
                                                    <!-- eslint-disable -->
                                                    <label v-for="r in passivas" class="d-flex mx-2">
                                                        <b-button :pressed.sync="checkedp.find(x => x.id === r.id).state" v-on:click="selectedP(r.id)" class="col-12">
                                                            {{ tipos.find(x => x.id === r.idTipoAto).descricao }} nº {{ r.numero }} - {{ moment(r.dataCriacao).format('DD/MM/YYYY') }}.
                                                        </b-button>
                                                    </label>
                                                    <!-- eslint-enable -->
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <label>Ação</label>
                                                <div class="row">
                                                    <!-- eslint-disable -->
                                                    <div class="col-12">
                                                        <select class="form-control" v-model="ficha.acao">
                                                            <option v-for="acao in acoes" :value="acao.id">{{ acao.descricao }}</option>
                                                        </select>
                                                    </div>
                                                    <!-- eslint-enable -->
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="idOpcaoAtual == 2" class="text-center">
                                            <br />
                                            <i class="fas fa-check text-success fa-3x"></i>
                                            <br />
                                            <br />
                                            <h3>Remissão editada com sucesso.</h3>
                                        </div>
                                        <br />
                                        <a @click="procurar()" v-if="idOpcaoAtual >= 0 && idOpcaoAtual < 2" href="#" class="btn btn-info btn-block">{{ opcaoAtual[0].texto }}</a>
                                        <a @click="salvar()" v-if="idOpcaoAtual == 1" href="#" class="btn btn-info btn-block">{{ opcaoAtual[1].texto }}</a>
                                        <a @click="idOpcaoAtual--" href="#" v-if="idOpcaoAtual >= 2" class="btn btn-sm btn-info btn-block">Voltar</a>
                                    </form>
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>

    </div>
</template>

<script>
    /* eslint-disable */
    import moment from 'moment'
    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'EditAtos',
        data: () => {
            return ({
                tipos: null,
                checkeda: null,
                checkedp: null,
                ficha: {
                    numAtiva: null,
                    numPassiva: null,
                    acao: 0,
                },
                fichaProcura: {
                    numAtiva: null,
                    numPassiva: null,
                    acao: 0,
                },
                acoes: "",
                idOpcaoAtual: 0,
                isAddingUsuario: false,
                opcaoAtual: [{ id: 0, texto: "Procurar Atos" },
                { id: 1, texto: "Confirmar alteração da remissão" },
                { id: 2, texto: "Salvar remissão" }],
                pesquisa: "",
                ativas: null,
                passivas: null,
                user: null,
                iconeItem1: "fas fa-times text-warning",
                iconeItem2: "fas fa-times text-warning",
                iconeItem3: "fas fa-times text-warning",
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton
        },
        methods: {
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        //this.$swal("Oops!", error.response.data, "error");
                    })
            },
            selectedA: function (id) {
                this.ficha.numAtiva = id;
                this.checkeda.map(e => {
                    e.state = false
                });
                this.checkeda.find(x => x.id === id).state = true;
            },
            selectedP: function (id) {
                this.ficha.numPassiva = id;
                this.checkedp.map(e => e.state = false);
                this.checkedp.find(x => x.id === id).state = true;
            },
            procurar: function () {
                if (this.fichaProcura.numAtiva === null || this.fichaProcura.numPassiva === null || this.fichaProcura.numAtiva === "" || this.fichaProcura.numPassiva === "") {
                    this.$swal("Erro", "Insira o número dos Atos para fazer a procura.", "error");
                } else {
                    api.post("/Remissao/Procurar", this.fichaProcura)
                    .then(result => {
                        this.ativas = result.data.ativa;
                        this.checkeda = this.ativas.map(e => { return { id: e.id, state: false } });
                        this.passivas = result.data.passiva;
                        this.checkedp = this.passivas.map(e => { return { id: e.id, state: false } });
                        this.ficha.numAtiva = null;
                        this.ficha.numPassiva = null;
                        if (this.idOpcaoAtual < 1) this.idOpcaoAtual++;
                    })
                    .catch(error => {
                        if (error.response.status == 400) {
                            this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                        }
                        else this.$swal("Erro", error.response.data, "error");
                    });
                }
            },
            salvar: function () {
                if (this.ficha.acao == 0 || this.ficha.acao == '0') {
                    this.$swal("Erro", "Selecione uma Ação.", "error");
                } else
                if (this.ficha.numAtiva === null || this.ficha.numPassiva === null) {
                    this.$swal("Erro", "Você não selecionou todos os atos.", "error");
                } else
                if (this.ficha.numAtiva === this.ficha.numPassiva) {
                    this.$swal("Erro", "Você não fazer uma remissão entre o mesmo ato.", "error");
                } else {
                    api.post("/Remissao/UpdateRemissao", this.ficha)
                    .then(result => {
                        this.$swal("Sucesso", result.data.mensagem, "success")
                        .then(response => {
                            this.idOpcaoAtual++;
                        });
                    })
                    .catch(error => {
                        if (error.response.status == 400) {
                            this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                        }
                        else this.$swal("Erro", error.response.data, "error");
                    });
                }
            },
            fillSelects: function () {
                api.get("/Acao/Get")
                .then(result => {
                    this.acoes = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });

                api.get("/TipoAto/Get")
                .then(result => {
                    this.tipos = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            updateIndicators: function () {
                if (this.idOpcaoAtual == 0) {
                    this.iconeItem1 = "fas fa-times text-warning";
                    this.iconeItem2 = "fas fa-times text-warning";
                    this.iconeItem3 = "fas fa-times text-warning";
                }
                else {
                    this.iconeItem1 = "fas fa-check text-success";
                    this.iconeItem2 = "fas fa-check text-success";
                    this.iconeItem3 = "fas fa-check text-success";
                }
            },
            getCurrentAto: function () {
                let self = this;
                var id = this.$route.query.id;
                if (id != null || id != undefined || id != 0) {
                    api.get("/Remissao/GetOne?id=" + id)
                    .then(result => {
                        this.ficha.numAtiva = result.data.ficha.numeroa;
                        this.ficha.numPassiva = result.data.ficha.numerop;
                        this.ficha.acao = result.data.ficha.idAcao;
                        this.ficha.id = result.data.ficha.id;
                    })
                    .catch(error => {
                        this.$swal("Erro", error.response.data, "error");
                    });
                }
                else $router.push('/NotFound');
            }
        },
        mounted: function () {
            this.fillSelects();
            this.getCurrentAto();
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
        },
        updated: function () {
            this.updateIndicators();
        }
    }
    /* eslint-enable */
</script>

