<template>
  <div class="Home">
    <NavComponent id="NavComponent"></NavComponent>

    <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

    <div id="content" class="container">
      <div id="info" class="d-flex flex-row justify-content-center">
        <div
          id="box"
          class="d-flex flex-row justify-content-start align-items-center"
        >
          <div class="boxContent">
            <div class="container text-left">
              <hr />
              <h3 class="bold-title">
                <i class="fas fa-chart-area"></i> Estatísticas
              </h3>

              <hr />

              <h5 class="bold-title">Quantidade de atos por tipo</h5>

              <div class="d-flex flex-wrap">
                <CardDados
                  v-for="item in infoCards"
                  :titulo="item.titulo"
                  :valor="item.valor"
                  :icon="item.icon"
                  :key="item.index"
                />
              </div>

              <br />

              <h5 class="bold-title">
                Métricas adicionais
                <v-tooltip right>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon color="primary" dark v-bind="attrs" v-on="on">
                      {{ iconInfoToolTip }}
                    </v-icon>
                  </template>
                  <span
                    >Caso não tenha acesso entre em contato com um
                    administrador</span
                  >
                </v-tooltip>
              </h5>

              <BtnExterno :texto="textoBtn" :icon="this.iconBtn" />

              <hr />

              <h5 class="bold-title">
                Para um relatório completo sobre os dados do LegisOn, baixe e
                consulte a tabela abaixo
              </h5>

              <div class="d-flex">
                <v-btn
                  variant="tonal"
                  v-bind:color="activeBtn ? 'active-xlsx' : 'active-csv'"
                  class="border-right-zero border-right-zero"
                  @click="toggleExportAs('XLSX')"
                >
                  XLSX
                </v-btn>

                <v-btn
                  variant="tonal"
                  v-bind:color="!activeBtn ? 'active-xlsx' : 'active-csv'"
                  class="border-left-zero border-left-zero"
                  @click="toggleExportAs('CSV')"
                >
                  CSV
                </v-btn>

                <v-btn
                  variant="tonal"
                  size="x-large"
                  color="#0d8c1b"
                  class="ml-4"
                  style="color: #fff; width: 200px;"
                  @click="exportFileAs()"
                >
                  Baixar
                </v-btn>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-center"></div>
    </div>
  </div>
</template>

<script>
import NavComponent from "../../components/shared/NavComponent.vue";
import HeaderPadrao from "../../components/shared/HeaderPadrao.vue";
import CardDados from "./Components/CardDados.vue";
import BtnExterno from "./Components/BtnExterno.vue";

import {
  mdiBook,
  mdiFileDocumentEdit,
  mdiFileDocument,
  mdiFeather,
  mdiGoogleAnalytics,
  mdiInformationOutline,
} from "@mdi/js";

export default {
  /* eslint-disable */
  name: "Home",
  data: () => {
    return {
      loading: false,
      activeBtn: true,
      exportAs: "XLSX",
      infoCards: [],
      textoBtn: "Google Analytics",
      iconBtn: mdiGoogleAnalytics,
      iconInfoToolTip: mdiInformationOutline,
    };
  },
  beforeRouteLeave(to, from, next) {
    localStorage.setItem("prevPage", from.path);
    next();
  },
  components: {
    NavComponent,
    HeaderPadrao,
    CardDados,
    BtnExterno,
  },
  computed: {},
  methods: {
    toggleExportAs: function(fileType) {
      if (fileType != this.exportAs) this.activeBtn = !this.activeBtn;
      this.exportAs = fileType;
    },
    exportFileAs: function() {
      let type;
      this.exportAs === "XLSX"
        ? (type = "application/vnd.ms-excel")
        : (type = "text/csv;charset=utf-8");

      api
        .get("/Relatorio/DownloadRelatorio?tipoArquivo=" + this.exportAs, {
          responseType: "blob",
        })
        .then((response) => {
          const FILE = new Blob([response.data], { type: type });

          // Cria uma tag <a/> e posteriormente define seus atributos para o download.
          const url = URL.createObjectURL(FILE);
          const link = document.createElement("a");

          link.setAttribute("href", url);
          link.setAttribute("download", `LegisOnRelatorio.${this.exportAs}`);
          link.click();
        })
        .catch((err) => console.log(err));
    },
    getDados: function() {
      this.infoCards = [];
      api
        .get("/Relatorio/ConsultaAtos")
        .then((response) => {
          this.infoCards = response.data.toString();
          let arr = [];
          let qtdAtos = {
            titulo: "Quantidade de Atos",
            valor: response.data.qtdAtos.toString(),
            icon: mdiFeather,
          };
          let qtdAtosInseridos = {
            titulo: "Quantidade de Atos Inseridos",
            valor: response.data.qtdAtosInseridos.toString(),
            icon: mdiFileDocument,
          };
          let qtdAtosRepublicados = {
            titulo: "Quantidade de Atos Republicados",
            valor: response.data.qtdAtosRepublicados.toString(),
            icon: mdiFileDocumentEdit,
          };
          let mediaUltimos15dias = {
            titulo: "Media Ultimos 15 dias",
            valor: response.data.mediaUltimos15dias.toFixed(2),
            icon: mdiBook,
          };
          let mediaUltimos15diasNonZero = {
            titulo: "Media Ultimos 15 dias úteis",
            valor: response.data.mediaUltimos15diasNonZero.toFixed(2),
            icon: mdiBook,
          };
          let qtdTot15dias = {
            titulo: "Quantidade Total ultimos 15 dias",
            valor: response.data.qtdTot15dias.toString(),
            icon: mdiBook,
          };
          let mediaUltimos30dias = {
            titulo: "Media Ultimos 30 dias",
            valor: response.data.mediaUltimos30dias.toFixed(2),
            icon: mdiBook,
          };
          let mediaUltimos30diasNonZero = {
            titulo: "Media Ultimos 30 dias úteis",
            valor: response.data.mediaUltimos30diasNonZero.toFixed(2),
            icon: mdiBook,
          };
          let qtdTot30dias = {
            titulo: "Quantidade Total ultimos 30 dias",
            valor: response.data.qtdTot30dias.toString(),
            icon: mdiBook,
          };

          arr.push(qtdAtos);
          arr.push(qtdAtosInseridos);
          arr.push(qtdAtosRepublicados);
          arr.push(mediaUltimos15dias);
          arr.push(mediaUltimos15diasNonZero);
          arr.push(qtdTot15dias);
          arr.push(mediaUltimos30dias);
          arr.push(mediaUltimos30diasNonZero);
          arr.push(qtdTot30dias);

          this.infoCards = arr;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    this.getDados();
  },
};
</script>

<style>
/* Classes do boostrap e vuetify não estão funcionando */
.border-right-zero {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.border-right-zero {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.border-left-zero {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.border-left-zero {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.active-xlsx {
  color: #fff !important;
  background-color: #0d8c1b !important;
}
.active-csv {
  background-color: default;
}
</style>
