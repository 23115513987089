<template>
    <div class="AddTipoAto">
        <modal name="AddTipoAto" height="auto">
            <br />
            <div class="container">
                <div class="text-left">
                    <h4>Adicionando tipo de ato</h4>
                    <hr />
                    <form>
                        <div class="row">
                            <div class="col-md-12">
                                <label>Descrição</label>
                                <input @keyup.enter="salvarTipoAto()" type="text" v-model="descricao" class="form-control" />
                            </div>
                        </div>
                        <br />
                        <a @click="salvarTipoAto()" href="#" class="btn btn-info btn-block">Salvar</a>
                    </form>
                    <br />
                </div>
            </div>
        </modal>
    </div>
</template>

<script>
    /* eslint-disable */
    export default {
        name: 'AddTipoAto',
        props: ["parent"],
        data: () => {
            return ({
                descricao: ""
            })
        },
        methods: {         
            salvarTipoAto: function () {
                api.post("/TipoAto/Add" + "?uid=" + this.$props.parent.user.id, {
                    descricao: this.descricao,
                })
                .then(result => {
                    this.$swal("Sucesso", result.data, "success")
                    .then(response => {
                        this.clearFields();
                        this.$props.parent.loadTiposAtos();
                        this.$modal.hide('AddTipoAto');
                    });
                })
                .catch(error => {
                    if (error.response.status == 400) {
                        this.$swal("Erro", "Não foi possível processar, verifique os campos.", "error");
                    }
                    else this.$swal("Erro", error.response.data, "error");
                });
            },
            clearFields: function () {
                this.descricao = "";
            }
        },
        mounted() {
        },
        updated() {
        }
    };
    /* eslint-enable */
</script>