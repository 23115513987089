<template>
    <div id="Loading">
        <div id="overlay" :class="'overlay ' + show"></div>
        <div id="spanner" :class="'LoadingFullscreen spanner ' + show"> 
            <v-overlay opacity="0.7">
                <v-img :lazy-src="require(`../../assets/imgs/LoadingLegison.svg`)"
                    :src="require(`../../assets/imgs/LoadingLegison.svg`)"
                    alt="Logo LegisOn"
                    contain
                    absolute
                    max-width="220px" />
                <b-spinner id="loadingSpinner"></b-spinner>
                <p class="mt-2" id="loadingText">Carregando...</p>
            </v-overlay>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Loading',
        props: ['show']
    }
</script>

<style>
    @import './Loading.css';
</style>
