<template>
    <a href="#" class="float">
        <i :class="icon + ' my-float'"></i>
    </a>
</template>

<style>
    .float {
        position: fixed;
        width: 60px;
        height: 60px;
        bottom: 40px;
        right: 40px;
        background-color: #0d8c1a;
        color: #FFF;
        border-radius: 50px;
        text-align: center;
        box-shadow: 2px 2px 3px #999;
    }

    .my-float {
        margin-top: 21px;
        margin-left: 7px;
        color: #fff;
        font-size: 20px;
    }

    .float:hover {
        text-decoration: none;
        color:#fff;
    }
</style>
<script>
    export default {
        name: 'FloatButton',
        props: ['icon'],
        data: () => {
            return ({
            })
        },
        components: {
        }
    }
</script>

