<template>
    <div class="Grupos">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />

                            <h3 class="bold-title"><i class="fas fa-user-friends"></i> Grupos</h3>
                            <br />

                            <div class="row">
                                <input type="text" v-on:keyup="searchTimeout" v-model="pesquisa" class="form-control" placeholder="Pesquise pelo nome do grupo" />
                            </div>

                            <br />

                            <div class="row" id="unseen">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>

                                    <!-- eslint-disable -->
                                    <tbody>
                                        <tr v-for="grupo in grupos">
                                            <td>{{ grupo.id }}</td>
                                            <td>{{ grupo.nome }}</td>
                                            <td>
                                                <a class="text-primary" href="#" @click="editGrupo(grupo)"><i class="fas fa-edit"></i></a>
                                                <a class="text-danger" href="#" @click="removeGrupo(grupo)"><i class="fas fa-trash"></i></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <!-- eslint-enable -->
                                </table>
                            </div>
                            
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>
        <AddGrupo :parent="this" />

        <EditGrupo :grupo="grupoEditando" />

        <a @click="addGrupo()"  class="btnFloat">
            <FloatButton id="FloatButton" icon="fas fa-plus"></FloatButton>
        </a>
       
    </div>
</template>

<script>
    /* eslint-disable */
    import AddGrupo from './components/Grupos/AddGrupo.vue';
    import EditGrupo from './components/Grupos/EditGrupo.vue';

    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'Grupos',
        data: () => {
            return ({
                isAddingUsuario: false,
                grupos: "",
                pesquisa: "",
                grupoEditando: "",
                user: null
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton,
            AddGrupo,
            EditGrupo
        },
        methods: {
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    })
            },
            searchTimeout: function (event) {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null
                }
                if (!['control', 'alt'].includes(event.key.toLowerCase()))
                    this.timeout = setTimeout(() => {
                        this.pesquisar();
                    }, 800);
            },
            pesquisar: function () {
                this.loadGrupos();
            },
            loadGrupos: function () {
                api.get("/Grupo/Get?pesquisa=" + this.pesquisa)
                .then(result => {
                    this.grupos = result.data;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            addGrupo: function () {
                this.$modal.show('AddGrupo')//.then(response => { this.loadGrupos(); });
            },
            editGrupo: function (grupo) {
                this.grupoEditando = grupo;
                this.$modal.show('EditGrupo');
            },
            removeGrupo: function (grupo) {
                this.$swal({
                    title: "Confirme",
                    text: "Você tem certeza que deseja excluir o grupo " + grupo.nome + "?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Remover',
                    cancelButtonText: 'Cancelar'
                }).then(response => {
                    if (response.isConfirmed) {
                        api.delete("/Grupo/Remove?id=" + grupo.id + "&uid=" + this.user.id).then(result => {
                            this.$swal("Sucesso", result.data, "success");
                            this.loadGrupos();
                        }).catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            }
        },
        mounted: function () {
            this.loadGrupos();
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
        }
    }
    /* eslint-enable */
</script>

