<template>
    <div class="Dashboard">
        <NavComponent id="NavComponent"></NavComponent>
        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>
        <div class="container-dialog">
            <v-row justify="center">
                <v-dialog v-model="dialog" persistent max-width="600px">
                    <v-card>


                        <v-card-text>
                            <v-card-text>

                                <v-container>
                                    <div class="text-left">
                                        <h4 class="title">Insira sua nova senha</h4>
                                        <hr />

                                        <div class="md-12">
                                            <label>Insira nova senha</label>
                                            <input type="password" v-model="password" class="form-control rule" />
                                            <span class="text-danger ruleAviso" v-if="SenhaAviso">{{ msg }}</span>

                                        </div>

                                        <div class="md-12">
                                            <label>Repita sua Nova Senha</label>
                                            <input type="password" v-model="confirmepassword" class="form-control" />
                                            <span class="text-danger ruleAviso" v-if="SenhaAviso1">{{ msg1 }}</span>
                                        </div>

                                        <br>
                                       
                                        <a @click="register" href="#" :class="classesButtons" >Alterar Senha</a>
                                    </div>
                                </v-container>


                            </v-card-text>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </div>
        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <!-- eslint-disable -->
                    <div class="boxContent">
                        <br />
                        <div class="container text-left">
                            <h5>Bem vindo {{ usuarioNome }}!</h5>
                            <h2>Painel de Controle</h2>
                            <hr />
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-6">
                                            <v-card elevation="2" class="text-white" color="primary">
                                                <!-- bg-info -->
                                                <div class="card-body">
                                                    <h4><i class="fas fa-file-signature"></i> {{
                                                            parseInt(qtdAtos).toLocaleString('pt-br', {
                                                                minimumIntegerDigits: 2
                                                            })
                                                    }} Atos</h4>
                                                </div>
                                            </v-card>
                                            <br />
                                        </div>

                                        <div class="col-md-6">
                                            <v-card elevation="2" class="text-white" color="primary">
                                                <div class="card-body">
                                                    <h4><i class="fas fa-users"></i> {{
                                                            parseInt(qtdUsuarios).toLocaleString('pt-br', {
                                                                minimumIntegerDigits: 2
                                                            })
                                                    }} Usuários </h4>
                                                </div>
                                            </v-card>
                                            <br />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <br />
                                    <div class="card">
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-6">
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/Cadastros/Atos/Adicionar')"
                                                        class="btn btn-default"><i class="fas fa-angle-right"></i>
                                                        Adicionar ato</a><br />
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/Consultas')" class="btn btn-default"><i
                                                            class="fas fa-angle-right"></i> Consultar ato</a><br />
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/Cadastros/Tipos/Orgaos')"
                                                        class="btn btn-default"><i class="fas fa-angle-right"></i>
                                                        Gerenciar órgãos</a><br />
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/Cadastros/Novidades')"
                                                        class="btn btn-default"><i class="fas fa-angle-right"></i>
                                                        Gerenciar novidades</a><br />
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/AtualizacoesAtos')"
                                                        class="btn btn-default"><i class="fas fa-angle-right"></i>
                                                        Solicitação de atualização</a><br />
                                                </div>

                                                <div class="col-6">
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/Configuracoes/Usuarios')"
                                                        class="btn btn-default"><i class="fas fa-angle-right"></i>
                                                        Gerenciar usuários</a><br />
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/Cadastros/Tipos/Temas')"
                                                        class="btn btn-default"><i class="fas fa-angle-right"></i>
                                                        Gerenciar temas</a><br />
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/Configuracoes/Acessos')"
                                                        class="btn btn-default"><i class="fas fa-angle-right"></i>
                                                        Gerenciar permissões</a><br />
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/Cadastros/Erros')"
                                                        class="btn btn-default"><i class="fas fa-angle-right"></i>
                                                        Gerenciar erros</a><br />
                                                    <a style="color: #000 !important"
                                                        @click="$router.push('/Cadastros/Sobre')"
                                                        class="btn btn-default"><i class="fas fa-angle-right"></i>
                                                        Gerenciar saiba mais</a><br />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <br />
                    </div>
                    <!-- eslint-enable -->
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>

        <footer>
            <img src="../assets/imgs/logo-GovEstado-SE.png" alt="Sergipe, Governo do Estado" />
        </footer>
    </div>
</template>

<script>
/* eslint-disable */
import axios from 'axios';

import NavComponent from '../components/shared/NavComponent.vue';
import HeaderPadrao from '../components/shared/HeaderPadrao.vue';
import AppButton from "@/components/AppButton.vue";
import InputComponent from "@/components/AppInput.vue";
import rules from "@/utils/rules.js";

export default {
    name: 'Dashboard',
    components: {
        NavComponent,
        HeaderPadrao,
        AppButton,
        InputComponent

    },
    data: () => {
        return ({
            usuario: "",
            usuarioNome: "",
            qtdUsuarios: 0,
            qtdAtos: 0,
            rules,
            dialog: false,
            password: '',
            confirmepassword: '',
            valid: '',
            msg: '',
            msg1: '',
            SenhaAviso: false,
            SenhaAviso1: false,
          
        })
    },

    methods: {


        getUserInfo() {
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            var token = localStorage.setItem("Token", cookie)
            var userNameCookie = this.$cookies.get('COOKIE_LEGISON_USER_NAME');
            if (!userNameCookie) {
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.usuario = response.data;
                        this.usuarioNome = response.data.nome;
                        this.$cookies.set('COOKIE_LEGISON_USER_NAME', this.usuarioNome);
                    })
                    .catch(error => {
                        this.$swal("Erro ao pegar informações de usuário. Por favor recarregue a página!", error.response.data, "error");
                    })
            }
            else this.usuarioNome = userNameCookie;
        },
        getAmountAtos() {
            var amountAtosCookie = this.$cookies.get('COOKIE_LEGISON_AMOUNT_ATOS');
            if (!amountAtosCookie) {
                api.get("/Public/GetAmoutOfAtos")
                    .then(response => {
                        this.qtdAtos = response.data;
                        this.$cookies.set('COOKIE_LEGISON_AMOUNT_ATOS', this.qtdAtos);
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    })
            }
            else this.qtdAtos = amountAtosCookie;

        },
        getAmountUsuarios() {
            api.get("/Public/GetAmoutOfUsers")
                .then(response => {
                    this.qtdUsuarios = response.data;
                })
                .catch(error => {
                    this.$swal("Oops!", error.response.data, "error");
                })
        },
        updateDashInfo() {
            var loginCookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            var userNameCookie = this.$cookies.get('COOKIE_LEGISON_USER_NAME');
            var amountAtosCookie = this.$cookies.get('COOKIE_LEGISON_AMOUNT_ATOS');

            api.get("/Public/GetAmoutOfAtos")
                .then(response => {
                    if (response.data !== amountAtosCookie) this.$cookies.set('COOKIE_LEGISON_AMOUNT_ATOS', response.data);
                })
                .catch(error => {
                    this.$swal("Oops!", error.response.data, "error");
                })

            api.get("/Usuario/GetUserData?hash=" + loginCookie)
                .then(response => {
                    if (response.data.nome !== userNameCookie) {
                        this.$cookies.set('COOKIE_LEGISON_USER_NAME', response.data.nome);
                        this.usuarioNome = response.data.nome;
                    }
                })
                .catch(error => {
                    // this.$swal("Oops!", error.response.data, "error"); 
                })
        },


       
        register() {
            console.log("dddd")
            
            const password = this.password;
            const confirmepassword = this.confirmepassword
            const IdUser = localStorage.getItem("IdUser");
            var loginCookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if ( password != confirmepassword ) {
                this.$swal("Senhas não conferem", e.response.data, "error");
            }

            else this.SenhaAviso1 = false;



            api.patch("/Usuario/UpdatePassword?hash=" + loginCookie, {
                IdUser,
                password,
            })
                .then((response) => {
                    if (response.status == 200) {
                        this.$swal("Senhas alteradas com sucesso", response.data, "success");
                        localStorage.setItem("FirstAcess", true)
                        this.dialog = false

                    }
                })
                .catch((e) => {
                    this.$swal("Erro ao editar senha", e.response.data, "error");
                });
        },
    },

    watch: {
        password(password) {
            if ((password.length < 8)) {
                this.SenhaAviso = true;
                this.msg = "A senha deve possuir no minimo de 8 caracteres"
               
            }
            else if (!/^([\w\s.\u00E0-\u00FC]{3,100})$/i.test(password)){
                this.SenhaAviso = true;
                this.msg = "Caractere Inválido";
                
            } 
                
            else this.SenhaAviso = false;
        },
        confirmepassword(confirmepassword) {
            if (confirmepassword.length < 8) {
                this.SenhaAviso1 = true;
                this.msg1 = "A senha deve possuir no minimo de 8 caracteres"
               
            }
            else if (this.password != confirmepassword) {
                this.SenhaAviso1 = true;
                this.msg1 = "As senhas não conferem"
               
            }
            else if (!/^([\w\s.\u00E0-\u00FC]{3,100})$/i.test(confirmepassword)){
                this.SenhaAviso = true;
                this.msg = "Caractere Inválido";
               
            } 

            else{
                this.SenhaAviso1 = false;
                
            } 
        }
    },
    computed: {
            classesButtons() {
                if(!this.password || !this.confirmepassword || this.SenhaAviso || this.SenhaAviso1 ) {
                    return ['btn', 'btn-info', 'btn-block', 'disabled']
                }else return ['btn', 'btn-info', 'btn-block']
            }
        },
    created() {

        const firstacess = localStorage.getItem("FirstAcess")
        var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
        var token = localStorage.setItem("Token", cookie)

        if (firstacess == "false") {
            this.dialog = true
        }
    },
    mounted: async function () {
        var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
        if (cookie) {
            this.getUserInfo();
            this.getAmountUsuarios();
            this.getAmountAtos();
            this.updateDashInfo();
        }

    },
}
    /* eslint-enable */
</script>




<style lang="scss">
p {
    padding-left: 15px;
    padding-top: 15px;
    font-size: x-large;
}

.title {
    padding-top: 15px;
    font-weight: bold;

}

.v-dialog {
    border-radius: 10px;
    box-shadow: 0px 6px 20px 5px rgba(114, 114, 114, 0.25);
}

.v-card__text {
    padding: 0;
}

</style>


