<template>
  <div class="card m-2" style="width: 20rem;">
    <p class="titulo bold-title">{{ this.titulo }}</p>

    <div class="d-flex justify-content-between">
      <div class="d-flex flex-column justify-content-center">
        <p class="bold-title legisOn-green pt-0 mb-0">{{ valor }}</p>
      </div>
      <div class="d-flex flex-column justify-content-center padding-icon">
        <v-icon color="#0d8c1b" x-large>{{ icon }}</v-icon>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardDados",
  data: () => {
    return {};
  },
  props: {
    titulo: String,
    valor: String,
    icon: String,
  },
  methods: {},
  mounted: function() {},
};
</script>

<style>
.titulo {
  font-size: 18px;
}
.padding-icon{
  padding: 0px 15px 0px 15px;
}
.legisOn-green {
  color: #0d8c1b;
  font-size: 28px;
}
</style>
