<template>
    <div class="cardatos">
        <v-hover v-slot="{ hover }">
            <div class="cardatos mt-5">
                <v-lazy v-model="isActive"
                    :options="{ threshold: .5 }"
                    transition="fade-transition"
                    class="cardatos">

                    <v-card :elevation="hover ? 4 : 2"
                            :class="{ 'on-hover': hover }"
                            color="#DFDFDF"
                            v-on:click="toggleShows(ato.id.toString())"
                            rounded="lg">

                        <div class="card-results d-flex flex-no-wrap align-items-center flex-column flex-sm-row w-100">
                            <div class="d-flex flex-no-wrap flex-column flex-sm-row align-items-center px-4 py-4 w-100 pr-0">
                                <v-img :src="require(`@/assets/imgs/logodecreto.png`)"
                                       alt="Logo do Governo do Estado"
                                       class="mb-3 mb-sm-0"
                                       contain
                                       max-height="46"
                                       max-width="37" />

                                <div class="d-flex flex-column text-left ml-sm-4 w-100">
                                    <div class="d-flex">
                                        <div class="my-auto col-md-4 justify-content-start">
                                            <v-card-title v-text="prepareTitle(ato)" class="text-sm-left text-center" />
                                            <v-card-subtitle v-text="prepareDate(ato)" class="text-sm-left text-center" />
                                        </div>

                                        <div class="my-auto col-md-7">
                                            <span class="ementaDesc text-sm-right text-center">{{ prepareEmenta(ato) }}</span>
                                        </div>

                                        <div class="my-auto col-md-1">
                                            <div class="d-flex">
                                                <a :id="'hist' + ato.id" class="text-secondary" href="#" v-on:click="$router.push('/Cadastros/Atos/Historico?id=' + ato.id)"><i class="fas fa-archive"></i></a>
                                                <b-tooltip :target="'hist' + ato.id" triggers="hover">
                                                    Histórico do Ato.
                                                </b-tooltip>

                                                <a :id="'edit' + ato.id" class="text-primary" href="#" v-on:click="$router.push('/Cadastros/Atos/Editar?id=' + ato.id)"><center><i class="fas fa-edit"></i></center></a>
                                                <b-tooltip :target="'edit' + ato.id" triggers="hover">
                                                    Editar Ato.
                                                </b-tooltip>
                                            </div>
                                            <div class="d-flex">
                                                <a :id="'del' + ato.id" class="text-danger" href="#" v-on:click="$parent.remove(ato)"><i class="fas fa-trash"></i></a>
                                                <b-tooltip :target="'del' + ato.id" triggers="hover">
                                                    Excluir Ato.
                                                </b-tooltip>

                                                <span v-if="ato.tipoAto.descricao !== 'Emenda Constitucional' && ato.tipoAto.descricao !== 'Parecer Jurídico'">
                                                    <span v-if="ato.assinatura && ato.assinatura === 'Completo'">
                                                        <a :id="'ass' + ato.id" class="text-danger" href="#" v-on:click="assinatura(ato, 1)"><i class="fas fa-file-signature"></i></a>
                                                        <b-tooltip :target="'ass' + ato.id" triggers="hover">
                                                            Marcar Assinatura Incompleta.
                                                        </b-tooltip>
                                                    </span>

                                                    <span v-else>
                                                        <a :id="'eass' + ato.id" class="text-success" href="#" v-on:click="assinatura(ato, 0)"><i class="fas fa-file-signature"></i></a>
                                                        <b-tooltip :target="'eass' + ato.id" triggers="hover">
                                                            Marcar Assinatura Completa.
                                                        </b-tooltip>
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <v-spacer />

                            <div class="d-flex px-4">
                                <v-expand-transition>
                                    <i class="fas fa-angle-down"></i>
                                </v-expand-transition>
                            </div>

                        </div>

                    </v-card>
                </v-lazy>
            </div>
        </v-hover>
        <div class="cardatos" v-if="show">
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="col-3">Tipo</th>
                        <th scope="col" class="col-2">Número</th>
                        <th scope="col" class="col-2">Data do Ato</th>
                        <th v-if="!ato.tipoAto.descricao.toLowerCase().startsWith('parecer')" scope="col" class="col-2">Publicação</th>
                        <th v-if="!ato.tipoAto.descricao.toLowerCase().startsWith('parecer')" scope="col" class="col-2">Republicação</th>
                        <th scope="col" class="col-1">Disponível</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ ato.tipoAto.descricao }}</td>
                        <td>{{ ato.numero }}</td>
                        <td>{{ moment(ato.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? moment(ato.dataAto).format('DD/MM/YYYY') : "Não Informado" }}</td>
                        <td v-if="!ato.tipoAto.descricao.toLowerCase().startsWith('parecer')">{{ moment(ato.dataPublicacao).format('DD/MM/YYYY') !== "01/01/0001" ? moment(ato.dataPublicacao).format('DD/MM/YYYY') : "Não Informado" }}</td>
                        <td v-if="!ato.tipoAto.descricao.toLowerCase().startsWith('parecer')">{{ moment(ato.dataRepublicacao).format('DD/MM/YYYY') !== "01/01/0001" ? moment(ato.dataRepublicacao).format('DD/MM/YYYY') : "Não Informado" }}</td>
                        <td class="justify-content-center">
                            <v-switch readonly class="ml-4 mt-0 pt-0" :input-value="ato.disponivel" @click="togglePublish(ato)" inset color="primary" />
                        </td>
                    </tr>
                    <!-- eslint-enable -->
                </tbody>
            </table>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="col-2">Situação</th>
                        <th scope="col" class="col-4">Origem</th>
                        <th scope="col" class="col-3">Parecer</th>
                        <th scope="col" class="col-2">Iniciativa</th>
                        <th scope="col" class="col-1">Conteúdo</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ loadSituacao(ato.idSituacao) }}</td>
                        <td>{{ atoinfo.origemString }}</td>
                        <td>{{ atoinfo.parecerString }}</td>
                        <td>{{ atoinfo.iniciativa }}</td>
                        <td>
                            <div class="d-flex justify-content-center justify-itens-center">
                                <div v-if="atoinfo.conteudo">
                                    <v-dialog v-model="dialogHTML"
                                              fullscreen>

                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'conteudo' + ato.id" class="text-primary" href="#" v-on="on"><i class="fas fa-file-code"></i></a>
                                            <b-tooltip :target="'conteudo' + ato.id" triggers="hover">
                                                Texto Original.
                                            </b-tooltip>
                                        </template>

                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ato.tipoAto.descricao }} {{ ato.numero }} {{ moment(ato.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ato.dataAto).format('DD/MM/YYYY') + ")" : "" }}</span>
                                                <v-spacer></v-spacer>

                                                <v-btn icon
                                                       dark
                                                       @click="dialogHTML = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>

                                            <v-card-text class="w-100">
                                                <br />
                                                <iframe class="w-100"  style="height: 85vh !important;"
                                                    :srcdoc="atoinfo.conteudo"
                                                    v-html="atoinfo.conteudo"
                                                    frameborder="0"
                                                    scrolling="yes"
                                                    marginheight="0"
                                                    marginwidth="0" />
                                            </v-card-text>

                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="fas fa-file-code"></i></a>
                                </div>
                                <div v-if="atoinfo.pdf">
                                    <v-dialog v-model="dialogPDF"
                                              fullscreen>
                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'pdf' + ato.id" class="text-primary" href="#" v-on="on"><i class="fas fa-file-pdf"></i></a>
                                            <b-tooltip :target="'pdf' + ato.id" triggers="hover">
                                                Arquivo Original em PDF.
                                            </b-tooltip>
                                        </template>
                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ato.tipoAto.descricao }} {{ ato.numero }} {{moment(ato.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ato.dataAto).format('DD/MM/YYYY') + ")" : ""}}</span>
                                                <v-spacer></v-spacer>
                                                <v-btn icon
                                                       dark
                                                       @click="dialogPDF = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <div id="ItemDisplay" class="h-100 d-flex justify-content-center flex-column" v-if="getSourcePdf()">
                                                <iframe :src="pdfsrc"
                                                        frameborder="0"
                                                        scrolling="yes"
                                                        marginheight="0"
                                                        marginwidth="0"
                                                        class="pdf" />
                                            </div>
                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="fas fa-file-pdf"></i></a>
                                </div>
                                <div v-if="atoinfo.doc">
                                    <v-dialog v-model="dialogDOC"
                                              fullscreen>
                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'doc' + ato.id" class="text-primary" href="#" v-on="on"><i class="fas fa-file-word"></i></a>
                                            <b-tooltip :target="'doc' + ato.id" triggers="hover">
                                                Arquivo Original em Doc.
                                            </b-tooltip>
                                        </template>
                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ato.tipoAto.descricao }} {{ ato.numero }} {{ moment(ato.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ato.dataAto).format('DD/MM/YYYY') + ")" : "" }}</span>
                                                <v-spacer></v-spacer>
                                                <v-btn icon
                                                       dark
                                                       @click="dialogDOC = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <div id="ItemDisplay" class="h-100 d-flex justify-content-center flex-column" v-if="getSourceDoc()">
                                                <iframe :src="docsrc"
                                                        frameborder="0"
                                                        scrolling="yes"
                                                        marginheight="0"
                                                        marginwidth="0"
                                                        class="pdf" />
                                            </div>
                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="fas fa-file-word"></i></a>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center justify-itens-center">
                                <div v-if="atoinfo.conteudoConsolidado">
                                    <v-dialog v-model="dialogHTMLcons"
                                              fullscreen>
                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'conteudoc' + ato.id" class="text-primary" href="#" v-on="on"><i class="far fa-file-code"></i></a>
                                            <b-tooltip placement="bottom" :target="'conteudoc' + ato.id" triggers="hover">
                                                Texto Atualizado.
                                            </b-tooltip>
                                        </template>
                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ato.tipoAto.descricao }} {{ ato.numero }} {{ moment(ato.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ato.dataAto).format('DD/MM/YYYY') + ")" : "" }}</span>
                                                <v-spacer></v-spacer>
                                                <v-btn icon
                                                       dark
                                                       @click="dialogHTMLcons = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <v-card-text class="w-100" style="height: 85vh !important;">
                                                <br />
                                                <iframe class="w-100 h-100"
                                                    :srcdoc="atoinfo.conteudoConsolidado"
                                                    v-html="atoinfo.conteudoConsolidado"
                                                    frameborder="0"
                                                    scrolling="yes"
                                                    marginheight="0"
                                                    marginwidth="0" />
                                            </v-card-text>
                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="far fa-file-code"></i></a>
                                </div>
                                <div v-if="atoinfo.pdfConsolidado">
                                    <v-dialog v-model="dialogPDFcons"
                                              fullscreen>
                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'pdfc' + ato.id" class="text-primary" href="#" v-on="on"><i class="far fa-file-pdf"></i></a>
                                            <b-tooltip placement="bottom" :target="'pdfc' + ato.id" triggers="hover">
                                                Arquivo Atualizado em PDF.
                                            </b-tooltip>
                                        </template>
                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ato.tipoAto.descricao }} {{ ato.numero }} {{ moment(ato.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ato.dataAto).format('DD/MM/YYYY') + ")" : "" }}</span>
                                                <v-spacer></v-spacer>
                                                <v-btn icon
                                                       dark
                                                       @click="dialogPDFcons = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <div id="ItemDisplay" class="h-100 d-flex justify-content-center flex-column" v-if="getPdfConsolidado()">
                                                <iframe :src="pdfcons"
                                                        frameborder="0"
                                                        scrolling="yes"
                                                        marginheight="0"
                                                        marginwidth="0"
                                                        class="pdf" />
                                            </div>
                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="far fa-file-pdf"></i></a>
                                </div>
                                <div v-if="atoinfo.docConsolidado">
                                    <v-dialog v-model="dialogDOCcons"
                                              fullscreen>
                                        <template v-slot:activator="{ on, attrs }">
                                            <a :id="'docc' + ato.id" class="text-primary" href="#" v-on="on"><i class="far fa-file-word"></i></a>
                                            <b-tooltip placement="bottom" :target="'docc' + ato.id" triggers="hover">
                                                Arquivo Atualizado em Doc.
                                            </b-tooltip>
                                        </template>
                                        <v-card>
                                            <v-toolbar dark
                                                       color="primary">
                                                <span class="text-h5">{{ ato.tipoAto.descricao }} {{ ato.numero }} {{ moment(ato.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? "("  + moment(ato.dataAto).format('DD/MM/YYYY') + ")" : "" }}</span>
                                                <v-spacer></v-spacer>
                                                <v-btn icon
                                                       dark
                                                       @click="dialogDOCcons = false">
                                                    <v-icon>{{ icons.mdiClose }}</v-icon>
                                                </v-btn>
                                            </v-toolbar>
                                            <div id="ItemDisplay" class="h-100 d-flex justify-content-center flex-column" v-if="getDocConsolidado()">
                                                <iframe :src="doccons"
                                                        frameborder="0"
                                                        scrolling="yes"
                                                        marginheight="0"
                                                        marginwidth="0"
                                                        class="pdf" />
                                            </div>
                                        </v-card>
                                    </v-dialog>
                                </div>
                                <div v-else>
                                    <a class="text-secondary" style="cursor:default !important"><i class="far fa-file-word"></i></a>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="col-6"><center>Temas</center></th>
                        <th scope="col" class="col-6"><center>Orgãos</center></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td><center><pre>{{ atoinfo.temas }}</pre></center></td>
                        <td><center><pre>{{ atoinfo.orgaos }}</pre></center></td>
                    </tr>
                </tbody>
            </table>
            <table class="table table-striped">
                <thead>
                    <tr>
                        <th scope="col" class="col-12"><center>Ementa</center></th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{{ ato.ementa }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
    import {
        prepareTitle as utilPrepareTitle,
        prepareDate as utilPrepareDate,
        preparePath as utilPreparePath,
    } from '@/utils/prepareTextAto.js';

    import { mdiClose, mdiFileCheck } from '@mdi/js';

    const moment = require('moment');

    /* eslint-disable */
    export default {
        name: 'CardAto',
        data: () => {
            return ({
                icons: {
                    mdiClose,
                    mdiFileCheck
                },
                pdfsrc: null,
                docsrc: null,
                doccons: null,
                pdfcons: null,
                dialogDOCcons: false,
                dialogHTMLcons: false,
                dialogPDFcons: false,
                dialogDOC: false,
                dialogHTML: false,
                dialogPDF: false,
                isActive: false,
                pathObj: null,
                showing: false,
                atoinfo: {
                    conteudo: null,
                    pdf: null,
                    doc: null,
                    pdfConsolidado: null,
                    docConsolidado: null,
                    conteudoConsolidado: null,
                    temas: "",
                    orgaos: "",
                    iniciativa: "",
                    origemString: "",
                    parecerString: "",
                    ficha: null
                },
                temas: [],
                orgaos: []
            })
        },
        props: {
            ato: {
                type: Object
            }
        },
        methods: {
            assinatura: function (ato, st) {
                var status = "Completo";
                var id = ato.id;
                if (st) status = 'Incompleto';
                this.$swal({
                    title: "Confirme",
                    text: "Marcar assinatura do ato " + ato.numero + " como " + status + "?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar'
                })
                .then(response => {
                    if (response.isConfirmed) {
                        api.post("/Ato/ToggleAssinatura?atoid=" + id)
                        .then(result => {
                            ato.assinatura = result.data.ass
                            this.$swal("Sucesso", result.data.message, "success");
                        })
                        .catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            },
            getDocConsolidado: function () {
                if (this.atoinfo.docConsolidado) {
                    if (this.doccons) return true;
                    const id = this.ato.id;
                    this.doccons = `${LEGISON_APIURL}/uploads/atos/${id}/${this.atoinfo.docConsolidado}`;
                    if (!this.doccons.endsWith('.doc') && !this.doccons.endsWith('.docx')) this.doccons += ".docx";
                    return true;
                }
                return false;
            },
            getPdfConsolidado: function () {
                if (this.atoinfo.pdfConsolidado) {
                    if (this.pdfcons) return true;
                    const id = this.ato.id;
                    this.pdfcons = `${LEGISON_APIURL}/uploads/atos/${id}/${this.atoinfo.pdfConsolidado}`;
                    if (!this.pdfcons.endsWith('.pdf')) this.pdfcons += ".pdf";
                    return true;
                }
                return false;
            },
            getSourceDoc: function () {
                if (this.atoinfo.doc) {
                    if (this.docsrc) return true;
                    const id = this.ato.id;
                    this.docsrc = `${LEGISON_APIURL}/uploads/atos/${id}/${this.atoinfo.doc}`;
                    if (!this.docsrc.endsWith('.doc') && !this.docsrc.endsWith('.docx')) this.docsrc += ".docx";
                    return true;
                }
                return false;
            },
            getSourcePdf: function () {
                if (this.atoinfo.pdf) {
                    if (this.pdfsrc) return true;
                    const id = this.ato.id;
                    this.pdfsrc = `${LEGISON_APIURL}/uploads/atos/${id}/${this.atoinfo.pdf}`;
                    if (!this.pdfsrc.endsWith('.pdf')) this.pdfsrc += ".pdf";
                    return true;
                }
                return false;
            },
            loadInfos: async function () {
                await api.get("/Tema/Get").then(response => {
                    this.temas = response.data;
                }).catch(err => {
                    this.$swal("Erro", err.response.data, "error");
                });

                await api.get("/Orgao/Get").then(response => {
                    this.orgaos = response.data;
                }).catch(err => {
                    this.$swal("Erro", err.response.data, "error");
                });

                this.getAtoInfo(this.ato);
            },
            getAtoInfo: async function (ato) {
                api.get("/Ato/GetOne?id=" + ato.id)
                .then(response => {
                    var newato = response.data;
                    this.atoinfo.ficha = newato.ficha;
                    this.getOrigemString(ato);
                    this.getParecerString(ato);
                    this.getIniciativa(ato);
                    this.getConteudo(ato);
                    this.atoinfo.pdf = newato.conteudo.caminhoPDF;
                    if (!this.atoinfo.pdf) this.atoinfo.pdf = newato.conteudo.caminhoDocx;
                    var temasAto = newato.ficha.idTemas;
                    if (temasAto) {
                        temasAto = temasAto.split(';');
                        if (temasAto.length === 0) this.atoinfo.temas = "Nenhum tema relacionado."
                        for (var i = 0; i < temasAto.length; i++) {
                            if (temasAto[i] !== '') {
                                var t = this.temas.find(x => x.id === parseInt(temasAto[i]));
                                var br = "\n";
                                if (!temasAto[i + 1] || isNaN(parseInt(temasAto[i + 1]))) br = "";
                                if (t) this.atoinfo.temas += `${t.descricao}${br}`;
                            }
                        }
                    } else this.atoinfo.temas = "Nenhum tema relacionado."
                    var orgaosAto = newato.ficha.idOrgaos;
                    if (orgaosAto) {
                        orgaosAto = orgaosAto.split(';');
                        if (orgaosAto.length === 0) this.atoinfo.orgaos = "Nenhum orgão relacionado."
                        for (var i = 0; i < orgaosAto.length; i++) {
                            if (orgaosAto[i] !== '') {
                                var o = this.orgaos.find(x => x.id === parseInt(orgaosAto[i]));
                                var br = "\n";
                                if (!orgaosAto[i + 1] || isNaN(parseInt(orgaosAto[i + 1]))) br = "";
                                if (o) this.atoinfo.orgaos += `${o.nome}${br}`;
                            }
                        }
                    } 
                    else this.atoinfo.orgaos = "Nenhum orgão relacionado."
                }).catch(err => {
                    this.$swal("Erro", err.response.data, "error");
                });
            },
            getConteudo: async function (ato) {
                api.get('/Public/GetConteudoAto?atosids=' + ato.id).then(res => {
                    let content = res.data.content;
                    if (content?.length) {
                        let cons = content.find(x => x.consolidado && x.disp);
                        let orig = content.find(x => !x.consolidado && x.disp);
                        if (cons)
                            this.atoinfo.conteudoConsolidado = cons.textoFormatado ? cons.textoFormatado : null;
                        if (orig)
                            this.atoinfo.conteudo = orig.textoFormatado ? orig.textoFormatado : null;
                    }
                }).catch(err => {console.log(err)});
            },
            getIniciativa: function (ato) {
                if (ato.idIniciativa) {
                    api.get("/Iniciativa/GetOne?id=" + ato.idIniciativa)
                    .then(response => {
                        var iniciativa = response.data;
                        this.atoinfo.iniciativa = iniciativa.descricao;
                    })
                    .catch(err => {
                        this.$swal("Erro", err.response.data, "error");
                    });
                } else this.atoinfo.iniciativa = "Não Informado";
            },
            getParecerString: function (ato) {
                if (ato.idParecer) {
                    api.get("/Ato/GetOne?id=" + ato.idParecer).then(response => {
                        var parecer = response.data;
                        var data = moment(parecer.ficha.dataAto).format('DD/MM/YYYY') !== "01/01/0001" ? moment(parecer.ficha.dataAto).format('DD/MM/YYYY') : "";
                        if (data !== "") data = " - " + data;
                        this.atoinfo.parecerString = `${parecer.ficha.numero}${data}`
                    }).catch(err => {
                        this.$swal("Erro", err.response.data, "error");
                    })
                } else this.atoinfo.parecerString = "Não se Aplica";
            },
            getOrigemString: function (ato) {
                api.get("/TipoAto/Get").then(result => {
                    var tiposorigem = [];
                    tiposorigem.push({ id: 0, descricao: "Não se aplica", permiteCadastro: false });
                    var tpo = result.data.filter(x => !x.permiteCadastro);
                    tiposorigem = tiposorigem.concat(tpo);
                    var torig = tiposorigem.find(x => x.id === ato.idTipoOrigem);
                    if (!torig || torig.id === 0) this.atoinfo.origemString = "Não se Aplica";
                    else this.atoinfo.origemString = `${torig.descricao} Nº ${ato.numeroOrigem}`;
                }).catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            toggleShows: function (id) {
                if (this.showing) this.showing = false;
                else {
                    this.loadInfos();
                    this.showing = true;
                }
            },
            prepareTitle: function () {
                return utilPrepareTitle(this.ato.tipoAto.descricao, this.ato.numero).toUpperCase();
            },
            prepareDate: function () {
                var data = this.ato.dataAto;
                if (data.toString().startsWith("0001-01-01")) data = this.ato.dataPublicacao;
                if (data.toString().startsWith("0001-01-01")) data = "";
                if (data === "") return "";
                else return `de ${utilPrepareDate(data)}`.toUpperCase();
            },
            preparePath: function () {
                return utilPreparePath("Visualização Ato", this.$enumTipoAto[this.ato.tipoAto.id], this.ato.numero, this.ato);
            },
            prepareEmenta: function (ato) {
                var ementa = ato.ementa;
                // if (ementa && ementa.length > 180) ementa = ementa.substr(0, 180) + "...";
                return ementa;
            },
            loadSituacao: function (situacao) {
                var situacoes = ["Em Vigor", "Revogado", "Declarado", "Inconstitucional"];
                if (!situacao) return "Não Informado"
                else return situacoes[situacao - 1];
            },
            togglePublish(ato) {
                var status = ato.disponivel;
                var id = ato.id;
                var msg = "Disponibilizar este ato para o público?";
                if (status) msg = "Indisponibilizar este ato para o público?"
                 this.$swal({
                    title: "Confirme",
                    text: msg,
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Confirmar',
                    cancelButtonText: 'Cancelar'
                })
                .then(response => {
                    if (response.isConfirmed) {
                        if (ato.disponivel) {
                            api.post("/Ato/Unpublish?id=" + id)
                            .then(result => {
                                ato.disponivel = false;
                                this.$swal("Sucesso", "Ato Indisponibilizado ao público.", "success");
                            })
                            .catch(error => {
                                this.$swal("Erro", error.data, "error");
                            });
                        }else {
                            api.post("/Ato/Publish?id=" + id)
                            .then(result => {
                                ato.disponivel = true;
                                this.$swal("Sucesso", "Ato Disponibilizado ao público.", "success");
                            })
                            .catch(error => {
                                this.$swal("Erro", error.data, "error");
                            });
                        }
                    }
                });
            }
        },
        computed: {
            show: function () {
                return this.showing;
            }
        }
    }
    /* eslint-enable */
</script>

<style>
    @import './CardAto.css';
</style>
