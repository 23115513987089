<template>
    <div class="Temas">
        <NavComponent id="NavComponent"></NavComponent>

        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>

        <div id="content" class="container">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="boxContent">
                        <div class="container text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-list-ol"></i> Temas</h3>
                            <br />
                            <div class="row">
                                <input type="text" @keyup="searchTimeout()" v-model="pesquisa.desc" class="form-control" placeholder="Pesquise pela descrição do tema" />
                            </div>
                            <br />
                            <div class="row" id="unseen">
                                <table class="table table-striped">
                                    <thead>
                                        <tr>
                                            <th scope="col">Id</th>
                                            <th scope="col">Descrição</th>
                                            <th scope="col">Ações</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <!-- eslint-disable -->
                                        <tr v-for="tema in temaArr">
                                            <td>{{ tema.id }}</td>
                                            <td>{{ tema.descricao }}</td>
                                            <td>
                                                <a class="text-primary" href="#" @click="editTema(tema)"><i class="fas fa-edit"></i></a>
                                                <a class="text-danger" href="#" @click="removeTema(tema)"><i class="fas fa-trash"></i></a>
                                            </td>
                                        </tr>
                                        <!-- eslint-enable -->
                                    </tbody>
                                </table>
                            </div>
                            <center>
                                <v-btn v-if="displaySeeMore"
                                       plain
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                                <!--
                                <v-btn v-if="displaySeeMore"
                                       plain
                                       :loading="fetching"
                                       elevation="0"
                                       color="#888"
                                       width="300"
                                       @click="handleLoadMore"
                                       class="mt-6">
                                    Ver mais
                                </v-btn>
                                -->
                            </center>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
            <br />
            <br />
            <div class="d-flex flex-row justify-content-center">
            </div>
        </div>
        <AddTema :parent="this" />

        <EditTema :tema="temaEditando" />
        
        <a @click="addTema()"  class="btnFloat">
            <FloatButton id="FloatButton" icon="fas fa-plus"></FloatButton>
        </a>
       
    </div>
</template>

<script>
    /* eslint-disable */
    import AddTema from './components/Temas/AddTema.vue';
    import EditTema from './components/Temas/EditTema.vue';

    import FloatButton from '../../../components/shared/FloatButton.vue';
    import NavComponent from '../../../components/shared/NavComponent.vue';
    import HeaderPadrao from '../../../components/shared/HeaderPadrao.vue';

    export default {
        name: 'Temas',
        data: () => {
            return ({
                isAddingUsuario: false,
                temas: "",
                temaEditando: "",
                pesquisa: {
                    desc: "",
                    count: 0,
                    results: 15,
                },
                request: 0,
                listener: 1,
                temaArr: [],
                user: null,
                timeout: null
            })
        },
        components: {
            NavComponent,
            HeaderPadrao,
            FloatButton,
            AddTema,
            EditTema
        },
        computed: {
            displaySeeMore: function () {
                return this.listener;
            },
        },
        methods: {
            getUser: function () {
                var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
                api.get("/Usuario/GetUserData?hash=" + cookie)
                    .then(response => {
                        this.user = response.data;
                    })
                    .catch(error => {
                        this.$swal("Oops!", error.response.data, "error");
                    })
            },
            resetload: function () {
                this.pesquisa.count = 0;
                this.temaArr = [];
                this.handleLoadMore();
            },
            load: function () {
                api.get("/Tema/Get?pesquisa=" + this.pesquisa.desc + "&count=" + this.pesquisa.count + "&results=" + this.pesquisa.results)
                .then(result => {
                    this.temas = result.data;
                    if (this.listener == 0 && this.temas.length >= this.pesquisa.results) this.listener = 1;
                    if (this.temas.length < this.pesquisa.results) this.listener = 0;
                    this.temaArr = this.temaArr.concat(this.temas);
                    this.pesquisa.count += this.pesquisa.results;
                    this.request = 1;
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
            },
            searchTimeout: function () {
                if (this.timeout) {
                    clearTimeout(this.timeout);
                    this.timeout = null
                }
                this.timeout = setTimeout(() => {
                    this.pesquisar();
                }, 800);
            },
            pesquisar: function () {
                this.temas = "";
                this.pesquisa.count = 0;
                this.temaArr = [];
                this.load();
            },
            addTema: function () {
                this.$modal.show('AddTema');
            },
            editTema: function (tema) {
                this.temaEditando = tema;
                this.$modal.show('EditTema');
            },
            removeTema: function (tema) {
                this.$swal({
                    title: "Confirme",
                    text: "Você tem certeza que deseja excluir o tema " + tema.descricao + "?",
                    icon: "info",
                    showConfirmButton: true,
                    showCancelButton: true,
                    confirmButtonText: 'Remover',
                    cancelButtonText: 'Cancelar'
                }).then(response => {
                    if (response.isConfirmed) {
                        api.delete("/Tema/Remove?id=" + tema.id + "&uid=" + this.user.id)
                        .then(result => {
                            this.$swal("Sucesso", result.data, "success");
                            var index = this.temaArr.findIndex(e => e.id === tema.id);
                            if (index > -1)
                                this.temaArr.splice(index, 1);
                            this.load();
                        })
                        .catch(error => {
                            this.$swal("Erro", error.data, "error");
                        });
                    }
                });
            },
            handleLoadMore: function () {
                this.listener = 1;
                this.request = 0;
                this.load();
            },
            getResults: function () {
                this.listener = 1;
                this.load();
            }
        },
        mounted: function () {
            this.getResults();
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');
            if (cookie) this.getUser();
        }
    }
    /* eslint-enable */
</script>

