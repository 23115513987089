<template>
    <main>
        <NavComponent id="NavComponent"></NavComponent>
        <HeaderPadrao id="HeaderComponent"></HeaderPadrao>
        <div id="content" class="container container-about">
            <div id="info" class="d-flex flex-row justify-content-center">
                <div id="box" class="d-flex flex-row justify-content-start align-items-center">
                    <div class="w-100">
                        <div class="w-100 container-box text-left">
                            <br />
                            <h3 class="bold-title"><i class="fas fa-book-open"></i>Saiba Mais</h3>
                            <br />
                            <div class="about-nav">
                                <NavSaibaMais style="margin-bottom: 0.2rem !important;"></NavSaibaMais>
                                <div id="ItemDisplay">
                                    <div class="text-center">
                                        <h2>Linha do Tempo Atual</h2>
                                    </div>
                                    <img id="LinhaTempo" v-if="linhaTempoAtual.caminhoImagem" :src="this.linhaTempoURL" alt="Imagem da Linha do Tempo Atual">
                                    <a id="botao-atualizar" @click="dialog = true" href="#" class="btn btn-info botao-criar">
                                        {{ "Atualizar Linha do Tempo" }}
                                    </a>
                                    <v-modal>
                                        <v-row>
                                            <v-dialog v-model="dialog" persistent max-width="600px">
                                                <v-card>
                                                    <hr>
                                                    <v-card-text>
                                                        <v-container>
                                                            <v-row class="text-left" id="">
                                                                <v-col cols="12">
                                                                    <div class="container-tipo">Anexo</div>
                                                                    <v-file-input ref="fileInput" accept="image/*"
                                                                        v-model="imagemLinhaTempo"
                                                                        label="Escolha um arquivo">
                                                                    </v-file-input>
                                                                </v-col>
                                                            </v-row>
                                                        </v-container>
                                                        <small>*Indica um campo obrigatório</small>
                                                    </v-card-text>
                                                    <v-card-actions>
                                                        <v-spacer></v-spacer>
                                                        <v-btn color="red darken-1" text 
                                                            @click="closeModal()">
                                                            Fechar
                                                        </v-btn>
                                                        <v-btn color="green darken-1" text
                                                            @click="dialog = salvarLinhaTempo()">
                                                            Enviar
                                                        </v-btn>
                                                    </v-card-actions>
                                                </v-card>
                                            </v-dialog>
                                        </v-row>
                                    </v-modal>
                                </div>
                                <br>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <br />
    </main>
</template>
      
<script>

import NavComponent from "../../../components/shared/NavComponent.vue";
import HeaderPadrao from "../../../components/shared/HeaderPadrao.vue";
import NavSaibaMais from "./components/NavSaibaMais.vue";
import { Api } from "../../../Service/api"


export default {
    name: "LinhaTempo",
    components: {
        NavComponent,
        HeaderPadrao,
        NavSaibaMais,
    },
    data: () => {
        return {
            loading: false,
            user: null,
            linhaTempoAtual: "",
            imagemLinhaTempo: "",
            linhaTempoURL: "",
            dialog: false,
        };
    },
    methods: {
        getUser: function () {
            var cookie = this.$cookies.get("COOKIE_LEGISON_TOKEN");
            /* eslint-disable */
            api.get("/Usuario/GetUserData?hash=" + cookie)
                .then((response) => {
                    this.user = response.data;
                })
                .catch((error) => {
                    this.$swal("Oops!", error.response.data, "error");
                });
        },
        getLinhaTempoAtual: async function () {
            await api.get("/SaibaMais/GetLinhaTempoAtual")
                .then(result => {
                    this.linhaTempoAtual = result.data;
                    this.linhaTempoURL = this.linhaTempoAtual.caminhoImagem.replace('wwwroot', Api.defaults.baseURL);
                })
                .catch(error => {
                    this.$swal("Erro", error.response, "error");
                });
        },
        clearFields: function () {
            this.imagemLinhaTempo = "";
        },
        salvarLinhaTempo: async function () {
            var cookie = this.$cookies.get('COOKIE_LEGISON_TOKEN');

            let formData = new FormData();
            formData.append('Arquivo', this.imagemLinhaTempo);
            formData.append('TipoArquivo', this.imagemLinhaTempo.type)

            await api.post("/SaibaMais/AddLinhaTempo?hash=" + cookie, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            })
                .then(result => {
                    this.$swal("Success", `Linha do tempo atualizada`, "success")
                    .then(() => {
                        this.clearFields();
                        this.dialog = false;
                        window.location.reload();
                    })
                })
                .catch(error => {
                    this.$swal("Erro", error.response.data, "error");
                });
        },
        closeModal: function() {
            this.clearFields();
            this.dialog = false;
        },
    },
    mounted: function () {
        this.getUser();
        this.getLinhaTempoAtual();
    }
}
</script>
      
<style>
@import './LinhaTempo.css';
</style>
      