<template>
  <div class="EditOrgao">
    <modal name="EditNovidade" height="auto">
      <br />
      <div v-if="novidade != ''" class="container">
        <div class="text-left">
          <h4>Editando novidade</h4>
          <hr />
          <form>
            <div class="row">
              <div class="col-md-12">
                <label>Descrição</label>
                <textarea
                  @keyup.enter="salvar()"
                  type="text"
                  v-model="novidade.title"
                  class="form-control"
                  rows="4"
                ></textarea>
              </div>
            </div>
            <div class="row mt-0 align-items-end justify-content-between">
              <div class="col-md-4">
                <label>Data<span class="text-danger">*</span></label>
                <input
                  @keyup.enter="salvar()"
                  type="date"
                  v-model="parsedDate"
                  class="form-control"
                  :max="maxData"
                />
              </div>
              <div class="col-md-4">
                <label class="mb-1">
                  Funcionalidade?<span class="text-danger">*</span>
                </label>
                <v-switch
                  hide-details
                  class="mt-0"
                  v-model="novidade.feature"
                  :label="`${featureSwitch}`"
                />
              </div>
              <div class="col-md-4">
                <a @click="salvar()" href="#" class="btn btn-info btn-block"
                  >Salvar</a
                >
              </div>
            </div>
          </form>
          <br />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
/* eslint-disable */
import moment from "moment";

export default {
  name: "EditNovidade",
  props: ["novidade"],
  data: () => {
    return {
      user: null,
      data: "",
      maxData: moment().format("YYYY-MM-DD"),
    };
  },
  computed: {
    parsedDate: {
      get: function() {
        this.data = moment(String(this.novidade.date)).format("YYYY-MM-DD");
        return moment(String(this.novidade.date)).format("YYYY-MM-DD");
      },
      set: function(value) {
        let newDate = value;
        this.data = newDate;
      },
    },
    featureSwitch: function() {
      return this.novidade.feature === false ? "Não" : "Sim";
    },
  },
  methods: {
    isAfterMaxDate: function() {
      return moment(this.data).isAfter(moment().format("YYYY-MM-DD"));
    },
    getUser: function() {
      var cookie = this.$cookies.get("COOKIE_LEGISON_TOKEN");
      api
        .get("/Usuario/GetUserData?hash=" + cookie)
        .then((response) => {
          this.user = response.data;
        })
        .catch((error) => {
          this.$swal("Oops!", error.response.data, "error");
        });
    },
    salvar: function() {
      if (!this.isAfterMaxDate()) {
        this.data = moment(String(this.data)).format(
          "YYYY-MM-DDT00:00:00-03:00"
        );
        api
          .post("/Novidades/Edit", {
            id: this.$props.novidade.id,
            data: this.data,
            descricao: this.$props.novidade.title,
            feature: this.$props.novidade.feature,
          })
          .then((result) => {
            this.$swal("Sucesso", result.data, "success").then(() => {
              this.$parent.resetload();
              this.$modal.hide("EditNovidade");
            });
          })
          .catch((error) => {
            if (error.response.status == 400) {
              this.$swal(
                "Erro",
                "Não foi possível processar, verifique os campos.",
                "error"
              );
            } else this.$swal("Erro", error.response.data, "error");
          });
      } else {
        this.$swal(
          "Erro",
          "Não foi possível processar, a data não pode ser posterior a data atual.",
          "error"
        );
      }
    },
  },
  mounted() {
    var cookie = this.$cookies.get("COOKIE_LEGISON_TOKEN");
    if(cookie) this.getUser();
  },
};
</script>
